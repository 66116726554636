import React from "react";

import {
	Button,
	Divider,
	Flex,
	Grid,
	GridItem,
	Icon,
	IconButton,
	Input,
	Modal as ChakraModal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Text,
	useToast,
} from "@chakra-ui/react";
import { DateTime } from "luxon";
import { FiAlertTriangle } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

import { getIteListThunk, postPublicarIteSegunEstadoThunk } from "store/ITE/thunks";
import { useAppDispatch } from "store/store";

interface ModalProps {
	isOpen: boolean;
	assetId: string;
	defaultDate: DateTime;
	minDate: DateTime;
	maxDate?: DateTime;
	onClose: () => void;
}

export const IteNuevaModal: React.FC<ModalProps> = (props) => {
	const { isOpen, assetId, defaultDate, minDate, maxDate, onClose } = props;
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const toast = useToast();

	const [date, setDate] = React.useState(defaultDate);

	const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
		e.preventDefault();
		const { value } = e.target;
		setDate(DateTime.fromISO(value));
	};

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		if (!assetId) return;
		try {
			await dispatch(
				postPublicarIteSegunEstadoThunk({
					assetId,
					fecha: date.toISODate(),
				}),
			);
			await dispatch(getIteListThunk({ assetId }));
			navigate(window.location.pathname.split("/").slice(0, -1).join("/"));
			toast({
				title: "ITE Registrada",
				status: "success",
				duration: 3000,
				isClosable: true,
			});
			onClose();
		} catch (error) {
			toast({
				title: "Error al registrar ITE",
				status: "error",
				duration: 3000,
				isClosable: true,
			});
		}
	};

	return (
		<ChakraModal isOpen={isOpen} onClose={onClose} size="2xl">
			<React.Fragment>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader color="gray.800" textStyle="body2" pt="2rem">
						<Grid templateColumns="repeat(8, 3fr)" w="100%" alignItems={"center"}>
							<GridItem gridColumn={"1/2"} gridRow={"1/2"} pl=".5rem">
								<IconButton title="Volver" aria-label="back" aria-hidden="true" variant="icon-only" height="2rem" width="2rem">
									<Icon as={FiAlertTriangle} fontSize="3rem" color="red.500" strokeWidth={"1"} />
								</IconButton>
							</GridItem>
							<GridItem gridColumn={"2/8"} gridRow={"1/2"}>
								<Text textStyle="body1" color="gray.800" fontWeight="bold">
									Programar ITE Según Estado
								</Text>
							</GridItem>
						</Grid>
						<Divider w="98%" alignSelf={"center"} mt="1.25rem" />
					</ModalHeader>
					<ModalCloseButton onClick={() => onClose()} color="gray.500" />
					<ModalBody mb="2rem">
						<form id="nuevaITE" onSubmit={handleSubmit}>
							<Grid templateColumns="repeat(2, 1fr)" w="100%" alignItems={"baseline"}>
								<GridItem gridColumn={"1 / span 2"} gridRow={"1/2"}>
									<Text textStyle="body3" color="gray.600" lineHeight="1.25rem" mb="1rem">
										Va a proceder a programar una nueva ITE Según Estado en la fecha indicada. Esta acción no se puede
										deshacer.
									</Text>
									<Text textStyle="body3" color="gray.600" lineHeight="1.25rem">
										Tenga en cuenta que será posible iniciar los trabajos de una futura ITE desde un año antes de la
										fecha de visita programada.
									</Text>
								</GridItem>
								<GridItem gridColumn={"1 / span 2"} gridRow={"2/3"} pt="2rem">
									<React.Fragment>
										<Text textStyle="body3" color="gray.800" fontWeight="bold" lineHeight="1.25rem" mb="1rem">
											Fecha de Visita Programada
										</Text>
										<Input
											name="fechaProximaITE"
											h="38px"
											fontSize="body3"
											type="date"
											defaultValue={defaultDate.toISODate()}
											min={minDate.toISODate()}
											max={maxDate?.toISODate()}
											onChange={handleChange}
											lang="es-ES"
										/>
									</React.Fragment>
								</GridItem>
								<GridItem gridColumn={"1/9"} gridRow={"3/4"} pt="1.5rem">
									<Divider w="98%" alignSelf={"center"} mb="1.5rem" />
									<Flex direction="row" justifyContent="space-between">
										<Button
											type="submit"
											bg="gray.400"
											size="md"
											borderRadius="5px"
											h="2rem"
											w="20%"
											onClick={onClose}
											_hover={{ bg: "gray.500" }}
										>
											<Text textStyle="body3" color="white">
												Cancelar
											</Text>
										</Button>
										<Button
											type="submit"
											bg={"green.500"}
											size="md"
											ml="1rem"
											borderRadius="5px"
											mr=".5rem"
											h="2rem"
											w="auto"
										>
											<Text textStyle="body3" color="white">
												{"Nueva ITE Según Estado"}
											</Text>
										</Button>
									</Flex>
								</GridItem>
							</Grid>
						</form>
					</ModalBody>
				</ModalContent>
			</React.Fragment>
		</ChakraModal>
	);
};
