import React, { useState } from "react";

import { Box, Button, Divider, Flex, Grid, GridItem, Icon, IconButton, Text } from "@chakra-ui/react";
import { FiArrowLeft, FiCopy, FiEdit } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router-dom";

import { AssetInfo } from "common/components/AssetInfo";
import AssetModal from "common/components/AssetModal";
import HeaderText from "common/components/Header";
import RejectAssetModal from "common/components/RejectAssetModal";
import { SectionSelector } from "common/components/SectionSelector";
import { DuplicateAssetModal } from "components/ITE/components/DuplicateAssetModal";
import { IteValidationModal } from "components/ITE/components/IteValidationModal";
import { displayWhenPrint } from "helpers/displayWhenPrint";
import { capitalizeFirstLetter } from "helpers/text";
import { useAppSelector } from "store/store";

interface IProps {
	print?: boolean;
}
export const EstadoActivo: React.FC<IProps> = (props) => {
	const { print } = props;

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isRejectedModalOpen, setIsRejectedModalOpen] = useState(false);
	const [isValidationModalOpen, setIsValidationModalOpen] = useState(false);
	const [isDuplicationModalOpen, setIsDuplicationModalOpen] = useState(false);

	const iteSelected = useAppSelector((state) => state.ites.iteSelected);
	const features = useAppSelector((state) => state.user.features);
	const navigate = useNavigate();
	const location = useLocation();
	const previousLocation = location.state?.from;
	const hasDuplicateFeature = features.data.indexOf("duplicate-asset") >= 0;

	return (
		<React.Fragment>
			<Box padding="1rem" rounded="md" boxShadow="sm" bgColor="white" paddingBottom="2rem" paddingRight="1.5rem">
				{iteSelected.data && (
					<React.Fragment>
						<Flex>
							<Flex
								alignItems="center"
								justifyContent="center"
								width="2rem"
								height="2rem"
								_hover={{ bg: "gray.100", borderRadius: "5px" }}
								sx={{ display: displayWhenPrint(print) }}
							>
								<IconButton
									title="Volver"
									aria-label="back"
									variant="icon-only"
									height="2rem"
									width="2rem"
									onClick={() => navigate(previousLocation || "/")}
								>
									<Icon as={FiArrowLeft} fontSize="1.5rem" color="gray.500" strokeWidth={"1"} />
								</IconButton>
							</Flex>
							<Box mr="auto">
								<Text textStyle="h5" fontFamily="heading">
									{capitalizeFirstLetter(iteSelected.data.asset.nombre)}
								</Text>
							</Box>
							{hasDuplicateFeature && (
								<IconButton
									title="Duplicar activo"
									aria-label="back"
									variant="icon-only"
									height="1.5rem"
									width="1.5rem"
									onClick={() => setIsDuplicationModalOpen((v) => !v)}
								>
									<Icon as={FiCopy} fontSize="1.5rem" color="gray.500" strokeWidth={"1"} />
								</IconButton>
							)}
							{
								// eslint-disable-next-line no-constant-condition
								iteSelected.data.asset.por_validar === undefined || iteSelected.data.asset.por_validar ? (
									<>
										{features.data.includes("approve-asset") ? (
											<Flex gap=".5rem">
												<Button
													bg={"green.500"}
													size="md"
													borderRadius="5px"
													h="2rem"
													w={{ xs: "50%", sm: "auto" }}
													onClick={() => setIsModalOpen((v) => !v)}
												>
													<Text textStyle="body2" color="white">
														Aceptar Activo
													</Text>
												</Button>
												{["Entregado", "Reenviado"].includes(iteSelected.data.ite.estado) ? (
													<Button
														bg={"red.500"}
														size="md"
														borderRadius="5px"
														h="2rem"
														w={{ xs: "50%", sm: "auto" }}
														onClick={() => setIsValidationModalOpen((v) => !v)}
													>
														<Text textStyle="body2" color="white">
															Rechazar Activo
														</Text>
													</Button>
												) : (
													<></>
												)}
												<Button
													bg={"gray.400"}
													size="md"
													borderRadius="5px"
													h="2rem"
													w={{ xs: "50%", sm: "auto" }}
													onClick={() => setIsRejectedModalOpen((v) => !v)}
												>
													<Text textStyle="body2" color="white">
														Eliminar Activo
													</Text>
												</Button>
											</Flex>
										) : (
											<></>
										)}
									</>
								) : (
									<>
										{features.data.includes("edit-asset") ? (
											<IconButton
												title="Editar activo"
												aria-label="back"
												variant="icon-only"
												height="1.5rem"
												width="1.5rem"
												onClick={() => setIsModalOpen((v) => !v)}
											>
												<Icon as={FiEdit} fontSize="1.5rem" color="gray.500" strokeWidth={"1"} />
											</IconButton>
										) : (
											<></>
										)}
									</>
								)
							}
						</Flex>
						<Flex
							pl={{ xs: "0", sm: "1.5rem" }}
							mb={{ xs: "1rem", sm: "3rem" }}
							mt="0.5rem"
							justifyContent="space-between"
						>
							{<AssetInfo title="Información Registrada del Activo" asset={iteSelected.data.asset} />}
						</Flex>
						<Grid templateColumns="repeat(8, 1fr)" pr="1.5rem">
							<GridItem colStart={5}>
								<HeaderText label="Estado" />
							</GridItem>
							<GridItem colStart={7} justifySelf="flex-start">
								<HeaderText label="Resultado" />
							</GridItem>
						</Grid>
						<Divider mt=".1rem" />
						<SectionSelector sectionLabel="Portada" sectionRef="portada" estado={""} print={print} />
						<SectionSelector
							sectionLabel="Activo Digital"
							sectionRef="ld"
							estado={
								iteSelected.data.asset.fecha_entrega_bim
									? iteSelected.data?.inventario.fichaDeInventario.estado
									: "Sin activo digitalizado"
							}
							print={print}
						/>
						<SectionSelector
							sectionLabel="Revisión y registro de ITE"
							sectionRef="ite"
							estado={iteSelected.data.ite.estado}
							resultado={iteSelected.data.ite.resultado}
							print={print}
						/>
						<SectionSelector
							sectionLabel="Histórico de eventos"
							sectionRef="historico"
							print={print}
						/>
					</React.Fragment>
				)}
			</Box>
			{iteSelected.data?.asset && (
				<AssetModal
					isOpen={isModalOpen}
					onClose={() => setIsModalOpen(false)}
					asset={{ ...iteSelected.data.asset, empresa: iteSelected.data.ite.empresa }}
				/>
			)}
			{iteSelected.data?.asset && (
				<RejectAssetModal
					isOpen={isRejectedModalOpen}
					onClose={() => setIsRejectedModalOpen(false)}
					asset={{ ...iteSelected.data.asset, empresa: iteSelected.data.ite.empresa }}
				/>
			)}
			{iteSelected.data?.asset && (
				<IteValidationModal
					isOpen={isValidationModalOpen}
					onClose={() => setIsValidationModalOpen(false)}
					ite={iteSelected.data}
					iteAnswer={null}
					isAsset={true}
				/>
			)}
			<DuplicateAssetModal
				isOpen={isDuplicationModalOpen}
				onClose={() => setIsDuplicationModalOpen(false)}
				asset={iteSelected.data?.asset ?? null}
			/>
		</React.Fragment>
	);
};
