import React, { useState } from "react";

import { Box, Flex, Spinner } from "@chakra-ui/react";

import { ModalComentarios } from "common/components/Comentarios";
import { PaginationComponent } from "common/components/Pagination";
import { ActivoCompletoOutput } from "components/Portfolio/types/activos";
import { THUNK_STATE } from "config/types";
import { useAppSelector } from "store/store";

import { CertificacionHeader } from "./Header";
import CertificacionRow from "./Row";

export const Certificacion: React.FC = () => {
	const assetsInfo = useAppSelector((state) => state.portfolio.assetsInfo);
	const page = useAppSelector((state) => state.portfolio.page);

	const ref = React.useRef<HTMLDivElement>(null);
	const [activoComentario, setActivoComentaro] = useState<ActivoCompletoOutput>();

	const autoScrollToTop = React.useCallback(() => {
		ref.current?.scrollIntoView({ behavior: "smooth" });
	}, [page]);

	React.useEffect(() => {
		if (page > 1) {
			autoScrollToTop();
		}
	}, [page]);

	return (
		<React.Fragment>
			<Box ref={ref}>
				<CertificacionHeader />
				<Box overflow="hidden" overflowY="scroll" height="75vh">
					{assetsInfo.thunkState === THUNK_STATE.FULFILLED ? (
						assetsInfo.data.data.map((asset, i) => (
							<React.Fragment key={i}>
								<CertificacionRow assetId={asset.id} onOpenComentario={setActivoComentaro} />
							</React.Fragment>
						))
					) : (
						<Flex w="100%" my="2rem" justifyContent="center">
							<Spinner />
						</Flex>
					)}
					<PaginationComponent thunkState={assetsInfo.thunkState} pagination={assetsInfo.data.pagination} />
				</Box>
			</Box>
			<ModalComentarios asset={activoComentario} onClose={() => setActivoComentaro(undefined)} />
		</React.Fragment>
	);
};
