import { createAsyncThunk } from "@reduxjs/toolkit";
import { DateTime } from "luxon";

import { putCertificacion } from "api/certificacion";
import { postComentario } from "api/comentarios";
import { putIteReprogramar } from "api/ite";
import {
	getInfoActivos,
	getPortfolioStats,
	putEditarActivo,
	postNuevoActivo,
	putRechazarActivo,
	putValidarActivo,
} from "api/portfolio";
import { ITECommentInput } from "components/ITE/types/comentarios";
import { ActivoCompletoInput, ActivoCompletoOutput } from "components/Portfolio/types/activos";
import { PortfolioFilterSelection } from "components/Portfolio/types/filterData";
import { SLICE_NAMES } from "store/constant";

import { PortfolioSlice } from "./reducer";

const changeRequestValue = (value: string) => {
	const values = value.split(",");
	const newValues = values.map((v) => {
		if (v === "Registrado") {
			return "Validado";
		}
		if (v === "Entregado") {
			return "Por validar";
		}
		return v;
	});
	return newValues.join(",");
};

// 1. GET Portfolio Assets
export type GetInfoActivosQueryParams = {
	filterSelection?: PortfolioFilterSelection;
	page?: number;
	pageSize?: number;
	order?: string;
};

export const getAssetsWithFilterSelection = async (props: GetInfoActivosQueryParams) => {
	const { filterSelection, page, pageSize, order } = props;
	return await getInfoActivos({
		...(filterSelection?.estadoITE &&
			filterSelection.estadoITE.length && { estadoITE: changeRequestValue(filterSelection.estadoITE.toString()) }),
		...(filterSelection?.estadoLevantamiento &&
			filterSelection.estadoLevantamiento.length && {
				estadoLevantamiento: filterSelection.estadoLevantamiento.toString(),
			}),
		...(filterSelection?.resultadoITE &&
			filterSelection.resultadoITE.length && {
				resultadoITE: filterSelection.resultadoITE.toString(),
			}),
		...(filterSelection?.estadoFichaInventario &&
			filterSelection.estadoFichaInventario.length && {
				estadoFichaInventario: filterSelection.estadoFichaInventario.toString(),
			}),
		...(filterSelection?.ambito && filterSelection.ambito.length && { ambito: filterSelection.ambito.toString() }),
		...(filterSelection?.jefatura &&
			filterSelection.jefatura.length && { jefatura: filterSelection.jefatura.toString() }),
		...(filterSelection?.agrupacion &&
			filterSelection.agrupacion.length && { agrupacion: filterSelection.agrupacion.toString() }),
		...(filterSelection?.provincia &&
			filterSelection.provincia.length && { provincia: filterSelection.provincia.toString() }),
		...(filterSelection?.especialidad &&
			filterSelection.especialidad.length && { especialidad: filterSelection.especialidad.toString() }),
		...(filterSelection?.uso && filterSelection.uso.length && { uso: filterSelection.uso.toString() }),
		...(filterSelection?.relevancia &&
			filterSelection.relevancia.length && { relevancia: filterSelection.relevancia.toString() }),
		...(filterSelection?.tipologia &&
			filterSelection.tipologia.length && { tipologia: filterSelection.tipologia.toString() }),
		...(filterSelection?.empresa && filterSelection.empresa.length && { empresa: filterSelection.empresa.toString() }),
		...(filterSelection?.tecnico && filterSelection.tecnico.length && { tecnico: filterSelection.tecnico.toString() }),
		...(filterSelection?.searchText?.toString() && { searchText: filterSelection.searchText.toString() }),
		...(filterSelection?.porValidar &&
			filterSelection.porValidar.length && { porValidar: filterSelection.porValidar.toString() }),
		...(filterSelection?.via && { via: filterSelection.via.toString() }),
		...(filterSelection?.estacion && { estacion: filterSelection.estacion.toString() }),
		...(filterSelection?.categoriaMejoras &&
			filterSelection?.categoriaMejoras.length && { categoriaMejoras: filterSelection.categoriaMejoras.toString() }),
		...(filterSelection?.tipoEdificio &&
			filterSelection?.tipoEdificio.length && { tipoEdificio: filterSelection.tipoEdificio.toString() }),
		...(filterSelection?.indiceNEC &&
			filterSelection?.indiceNEC.length && {
				indiceNEC: filterSelection.indiceNEC.map((it) => it.slice(-1)).toString(),
			}),
		...(filterSelection?.prioridadReparacion &&
			filterSelection.prioridadReparacion.length && {
				prioridadReparacion: filterSelection.prioridadReparacion.toString(),
			}),
		...(filterSelection?.indiceNEE &&
			filterSelection.indiceNEE?.length && {
				indiceNEE: filterSelection.indiceNEE.map((it) => it.slice(-1)).toString(),
			}),
		...(filterSelection?.indiceNCN &&
			filterSelection?.indiceNCN?.length && {
				indiceNCN: filterSelection.indiceNCN.map((it) => it.slice(-1)).toString(),
			}),
		...(filterSelection?.indiceNAE &&
			filterSelection?.indiceNAE?.length && {
				indiceNAE: filterSelection.indiceNAE.map((it) => it.slice(-1)).toString(),
			}),
		...(filterSelection?.fechaVisita?.length && {
			fechaVisita: filterSelection.fechaVisita.join(",") || undefined,
		}),
		...(filterSelection?.fechaVisitaProxIte?.length && {
			fechaVisitaProxIte: filterSelection.fechaVisitaProxIte.join(",") || undefined,
		}),
		...(filterSelection?.fechaEntregaIte && {
			fechaEntregaIteFrom: filterSelection.fechaEntregaIte[0] || undefined,
			fechaEntregaIteTo: filterSelection.fechaEntregaIte[1] || undefined,
		}),
		...(filterSelection?.fechaEntregaBim && {
			fechaEntregaBimFrom: filterSelection.fechaEntregaBim[0] || undefined,
			fechaEntregaBimTo: filterSelection.fechaEntregaBim[1] || undefined,
		}),
		...(filterSelection?.fechaCertificacionIte && {
			fechaCertificacionIteFrom: filterSelection.fechaCertificacionIte[0] || undefined,
			fechaCertificacionIteTo: filterSelection.fechaCertificacionIte[1] || undefined,
		}),
		...(filterSelection?.fechaCertificacionBim && {
			fechaCertificacionBimFrom: filterSelection.fechaCertificacionBim[0] || undefined,
			fechaCertificacionBimTo: filterSelection.fechaCertificacionBim[1] || undefined,
		}),
		...(filterSelection?.iteEntregada !== undefined && {
			iteEntregada: filterSelection.iteEntregada,
		}),
		...(filterSelection?.bimEntregado !== undefined && {
			bimEntregado: filterSelection.bimEntregado,
		}),
		...(filterSelection?.iteCertificada !== undefined && {
			iteCertificada: filterSelection.iteCertificada,
		}),
		...(filterSelection?.bimCertificado !== undefined && {
			bimCertificado: filterSelection.bimCertificado,
		}),
		order,
		page,
		pageSize,
	});
};

export const getInfoActivosThunk = createAsyncThunk(
	`${SLICE_NAMES.PORTFOLIO}/getInfoActivos`,
	async (payload: GetInfoActivosQueryParams) => {
		const response = await getAssetsWithFilterSelection(payload);
		response.data.data.forEach((activo: ActivoCompletoOutput) => {
			if (activo.estado_ite === "Por validar") {
				activo.estado_ite = "Entregado";
			}
			if (activo.estado_ite === "Validado") {
				activo.estado_ite = "Registrado";
			}
		});
		response.data.data.forEach((activo: ActivoCompletoOutput) => {
			!!activo.nec_indice && (activo.nec_indice = `NCE${activo.nec_indice}`);
			!!activo.nee_indice && (activo.nee_indice = `NEE${activo.nee_indice}`);
			!!activo.ncn_indice && (activo.ncn_indice = `NCNE${activo.ncn_indice}`);
			!!activo.nae_indice && (activo.nae_indice = `NAE${activo.nae_indice}`);
		});
		return response.data;
	},
);

// 2. GET STATS
export type GetPortfolioStatsQueryParams = {
	filterSelection?: PortfolioFilterSelection;
};

export const getPortfolioStatsThunk = createAsyncThunk(
	`${SLICE_NAMES.PORTFOLIO}/getPortfolioStats`,
	async (payload: GetPortfolioStatsQueryParams) => {
		const { filterSelection } = payload;
		const response = await getPortfolioStats({
			...(filterSelection?.estadoITE &&
				filterSelection.estadoITE.length && { estadoITE: changeRequestValue(filterSelection.estadoITE.toString()) }),
			...(filterSelection?.estadoLevantamiento &&
				filterSelection.estadoLevantamiento.length && {
					estadoLevantamiento: filterSelection.estadoLevantamiento.toString(),
				}),
			...(filterSelection?.resultadoITE &&
				filterSelection.resultadoITE.length && {
					resultadoITE: filterSelection.resultadoITE.toString(),
				}),
			...(filterSelection?.estadoFichaInventario &&
				filterSelection.estadoFichaInventario.length && {
					estadoFichaInventario: filterSelection.estadoFichaInventario.toString(),
				}),
			...(filterSelection?.ambito && filterSelection.ambito.length && { ambito: filterSelection.ambito.toString() }),
			...(filterSelection?.jefatura &&
				filterSelection.jefatura.length && { jefatura: filterSelection.jefatura.toString() }),
			...(filterSelection?.agrupacion &&
				filterSelection.agrupacion.length && { agrupacion: filterSelection.agrupacion.toString() }),
			...(filterSelection?.provincia &&
				filterSelection.provincia.length && { provincia: filterSelection.provincia.toString() }),
			...(filterSelection?.especialidad &&
				filterSelection.especialidad.length && { especialidad: filterSelection.especialidad.toString() }),
			...(filterSelection?.uso && filterSelection.uso.length && { uso: filterSelection.uso.toString() }),
			...(filterSelection?.relevancia &&
				filterSelection.relevancia.length && { relevancia: filterSelection.relevancia.toString() }),
			...(filterSelection?.tipologia &&
				filterSelection.tipologia.length && { tipologia: filterSelection.tipologia.toString() }),
			...(filterSelection?.empresa &&
				filterSelection.empresa.length && { empresa: filterSelection.empresa.toString() }),
			...(filterSelection?.searchText?.toString() && { searchText: filterSelection.searchText.toString() }),
			...(filterSelection?.porValidar &&
				filterSelection.porValidar.length && { porValidar: filterSelection.porValidar.toString() }),
			...(filterSelection?.via && { via: filterSelection.via.toString() }),
			...(filterSelection?.estacion && { estacion: filterSelection.estacion.toString() }),
			...(filterSelection?.categoriaMejoras &&
				filterSelection?.categoriaMejoras.length && { categoriaMejoras: filterSelection.categoriaMejoras.toString() }),
			...(filterSelection?.tipoEdificio &&
				filterSelection?.tipoEdificio.length && { tipoEdificio: filterSelection.tipoEdificio.toString() }),
			...(filterSelection?.indiceNEC &&
				filterSelection?.indiceNEC.length && {
					indiceNEC: filterSelection.indiceNEC.map((it) => it.slice(-1)).toString(),
				}),
			...(filterSelection?.prioridadReparacion &&
				filterSelection.prioridadReparacion.length && {
					prioridadReparacion: filterSelection.prioridadReparacion.toString(),
				}),
			...(filterSelection?.indiceNEE &&
				filterSelection.indiceNEE?.length && {
					indiceNEE: filterSelection.indiceNEE.map((it) => it.slice(-1)).toString(),
				}),
			...(filterSelection?.indiceNCN &&
				filterSelection?.indiceNCN?.length && {
					indiceNCN: filterSelection.indiceNCN.map((it) => it.slice(-1)).toString(),
				}),
			...(filterSelection?.indiceNAE &&
				filterSelection?.indiceNAE?.length && {
					indiceNAE: filterSelection.indiceNAE.map((it) => it.slice(-1)).toString(),
				}),
			...(filterSelection?.fechaVisita?.length && {
				fechaVisita: filterSelection.fechaVisita.join(",") || undefined,
			}),
			...(filterSelection?.fechaVisitaProxIte?.length && {
				fechaVisitaProxIte: filterSelection.fechaVisitaProxIte.join(",") || undefined,
			}),
			...(filterSelection?.fechaEntregaIte && {
				fechaEntregaIteFrom: filterSelection.fechaEntregaIte[0] || undefined,
				fechaEntregaIteTo: filterSelection.fechaEntregaIte[1] || undefined,
			}),
			...(filterSelection?.fechaEntregaBim && {
				fechaEntregaBimFrom: filterSelection.fechaEntregaBim[0] || undefined,
				fechaEntregaBimTo: filterSelection.fechaEntregaBim[1] || undefined,
			}),
			...(filterSelection?.fechaCertificacionIte && {
				fechaCertificacionIteFrom: filterSelection.fechaCertificacionIte[0] || undefined,
				fechaCertificacionIteTo: filterSelection.fechaCertificacionIte[1] || undefined,
			}),
			...(filterSelection?.fechaCertificacionBim && {
				fechaCertificacionBimFrom: filterSelection.fechaCertificacionBim[0] || undefined,
				fechaCertificacionBimTo: filterSelection.fechaCertificacionBim[1] || undefined,
			}),
			...(filterSelection?.iteEntregada !== undefined && {
				iteEntregada: filterSelection.iteEntregada,
			}),
			...(filterSelection?.bimEntregado !== undefined && {
				bimEntregado: filterSelection.bimEntregado,
			}),
			...(filterSelection?.iteCertificada !== undefined && {
				iteCertificada: filterSelection.iteCertificada,
			}),
			...(filterSelection?.bimCertificado !== undefined && {
				bimCertificado: filterSelection.bimCertificado,
			}),
		});
		const keys = Object.keys(response.data.estado_ite);
		const newKeys = keys.map((key) => {
			if (key === "Por validar") {
				return "Entregado";
			} else if (key === "Validado") {
				return "Registrado";
			} else {
				return key;
			}
		});
		const newObject = {};
		newKeys.forEach((key, index) => {
			newObject[key] = response.data.estado_ite[keys[index]];
		});
		response.data.estado_ite = newObject;

		return response.data;
	},
);

// 3. PUT Editar Activo
export type putEditarActivoPropsType = {
	assetId: string;
	data: ActivoCompletoInput;
};

export const putEditarActivoThunk = createAsyncThunk(
	`${SLICE_NAMES.PORTFOLIO}/putGuardarActivo`,
	async (data: putEditarActivoPropsType, thunkApi) => {
		const response = await putEditarActivo(data);
		const portfolioState = (thunkApi.getState() as Record<string, PortfolioSlice>).portfolio;
		portfolioState.filterSelection
			? await thunkApi.dispatch(getInfoActivosThunk({ filterSelection: portfolioState.filterSelection }))
			: await thunkApi.dispatch(getInfoActivosThunk({}));
		return response.data;
	},
);

// 4. PUT Validar Activo
export type putValidarActivoPropsType = {
	assetId: string;
	data: ActivoCompletoInput;
};

export const putValidarActivoThunk = createAsyncThunk(
	`${SLICE_NAMES.PORTFOLIO}/putValidarActivo`,
	async (data: putValidarActivoPropsType, thunkApi) => {
		const response = await putValidarActivo(data);
		const portfolioState = (thunkApi.getState() as Record<string, PortfolioSlice>).portfolio;
		if (portfolioState.filterSelection) {
			await thunkApi.dispatch(getInfoActivosThunk({ filterSelection: portfolioState.filterSelection }));
			await thunkApi.dispatch(getPortfolioStatsThunk({ filterSelection: portfolioState.filterSelection }));
		} else {
			await thunkApi.dispatch(getInfoActivosThunk({}));
			await thunkApi.dispatch(getPortfolioStatsThunk({}));
		}
		return response.data;
	},
);

// 5. PUT Rechazar Activo
export type putRechazarActivoPropsType = {
	assetId: string;
	comentario: string;
};

export const putRechazarActivoThunk = createAsyncThunk(
	`${SLICE_NAMES.PORTFOLIO}/putRechazarActivo`,
	async (data: putRechazarActivoPropsType, thunkApi) => {
		const response = await putRechazarActivo(data);
		const portfolioState = (thunkApi.getState() as Record<string, PortfolioSlice>).portfolio;
		if (portfolioState.filterSelection) {
			await thunkApi.dispatch(getInfoActivosThunk({ filterSelection: portfolioState.filterSelection }));
			await thunkApi.dispatch(getPortfolioStatsThunk({ filterSelection: portfolioState.filterSelection }));
		} else {
			await thunkApi.dispatch(getInfoActivosThunk({}));
			await thunkApi.dispatch(getPortfolioStatsThunk({}));
		}
		return response.data;
	},
);

// 6. POST Nuevo Activo Validado
export type postNuevoActivoValidadoPropsType = {
	data: ActivoCompletoInput & { fechaVisitaIte: string };
};

export const postNuevoActivoValidadoThunk = createAsyncThunk(
	`${SLICE_NAMES.PORTFOLIO}/postNuevoActivoValidado`,
	async (payload: postNuevoActivoValidadoPropsType, thunkApi) => {
		const nuevoActivoResponse = await postNuevoActivo(payload);
		const nuevoActivo = nuevoActivoResponse.data;
		const response = await putValidarActivo({ assetId: nuevoActivo.id, data: payload.data });

		if (payload.data.fechaVisitaIte !== DateTime.now().toISODate()) {
			await putIteReprogramar({
				id: response.data.ite_id,
				fecha: payload.data.fechaVisitaIte,
				esReprogramacion: false,
			});
		}

		const portfolioState = (thunkApi.getState() as Record<string, PortfolioSlice>).portfolio;
		if (portfolioState.filterSelection) {
			await thunkApi.dispatch(getInfoActivosThunk({ filterSelection: portfolioState.filterSelection }));
			await thunkApi.dispatch(getPortfolioStatsThunk({ filterSelection: portfolioState.filterSelection }));
		} else {
			await thunkApi.dispatch(getInfoActivosThunk({}));
			await thunkApi.dispatch(getPortfolioStatsThunk({}));
		}
		return response.data;
	},
);

// 7. PUT certificacion
export type putFechaEntregaCertificacionPropsType = {
	iteId: string;
	tipo: "ite" | "bim";
	fecha: string | undefined;
};

export const putFechaEntregaCertificacionThunk = createAsyncThunk(
	`${SLICE_NAMES.PORTFOLIO}/putFechaEntregaCertificacion`,
	async (payload: putFechaEntregaCertificacionPropsType[]) => {
		await putCertificacion(payload);
		return payload;
	},
);

// 7. POST comment
export const postCommentThunk = createAsyncThunk(
	`${SLICE_NAMES.PORTFOLIO}/postCommentThunk`,
	async (payload: ITECommentInput) => {
		await postComentario(payload);
		return payload;
	},
);
