export type Choice = {
	label: string;
	value: string;
	[other: string]: string;
};

export const defaultValuesTipologias = [
	{
		value: "Almacén",
		label: "Edificio de Almacén",
	},
	{
		value: "Dependencia",
		label: "Dependencia en otros edificios",
	},
	{
		value: "Edificio principal de Base de mantenimiento",
		label: "Edificio principal de Base de mantenimiento",
	},
	{
		value: "Edificio técnico",
		label: "Edificio técnico",
	},
	{
		value: "Caseta",
		label: "Edificio tipo Caseta",
	},
	{
		value: "Oficinas",
		label: "Edificio de Oficinas",
	},
	{
		value: "Subestación",
		label: "Edificio de Subestación",
	},
	{
		value: "Garaje",
		label: "Edificio tipo Garaje",
	},
	{
		value: "Taller",
		label: "Edificio de Taller",
	},
	{
		value: "Otro",
		label: "Otro tipo de edificios",
	},
];
export const defaultValuesUsos = [
	{
		value: "Almacén",
		label: "Uso Almacén",
	},

	{
		value: "Garaje",
		label: "Uso Garaje",
	},
	{
		value: "Vestuarios",
		label: "Uso Vestuarios",
	},
	{
		value: "Laboratorios",
		label: "Uso Laboratorios",
	},
	{
		value: "Enclavamiento",
		label: "Uso Enclavamiento",
	},
	{
		value: "Oficina",
		label: "Uso Oficina",
	},
	{
		value: "Taller",
		label: "Uso Taller",
	},
	{
		value: "Aseos",
		label: "Uso Aseos",
	},
	{
		value: "Cocina/Comedor",
		label: "Uso Cocina/Comedor",
	},
	{
		value: "BTS",
		label: "Uso BTS (Base Transceiver Station)",
	},
	{
		value: "Autotransformador",
		label: "Uso Autotransformador",
	},
	{
		value: "Grupo electrógeno",
		label: "Uso Grupo de electrógeno",
	},
	{
		value: "Cuarto técnico",
		label: "Uso Cuarto técnico",
	},
	{
		value: "Equipo LAC",
		label: "Uso Equipo LAC",
	},
	{
		value: "Caseta de Medida Fiscal",
		label: "Uso Caseta de Medida Fiscal",
	},
	{
		value: "Caseta operadora",
		label: "Uso Caseta operadora",
	},
	{
		value: "Caseta de túnel",
		label: "Uso Caseta de túnel",
	},
	{
		value: "Uso Caseta de tren Tierra",
		label: "Uso Caseta de tren Tierra",
	},
	{
		value: "Cambiador de ancho",
		label: "Uso Cambiador de ancho",
	},
	// {
	// 	value: "Dependencia fuera de traza",
	// 	label: "Uso Dependencia fuera de traza",
	// },
	{
		value: "Centro de transformación",
		label: "Uso Centro de transformación",
	},
	{
		value: "Centro de regulación de circulación",
		label: "Uso Centro de regulación de circulación",
	},
	{
		value: "Centro de instalaciones",
		label: "Uso Centro de instalaciones",
	},
	{
		value: "Subestación eléctrica",
		label: "Uso Subestación Eléctrica",
	},
	{
		value: "Sala de comunicaciones",
		label: "Uso Sala Comunicaciones",
	},

	{
		value: "Otro",
		label: "Otros Usos",
	},
];
export const defaultValuesSistemas = [
	{
		value: "1.Sistema estructural",
		label: "1.Sistema estructural",
	},
	{
		value: "2.Sistema envolvente",
		label: "2.Sistema de envolventes",
	},
	{
		value: "3.Sistema de compartimentación",
		label: "3.Sistema de compartimentación",
	},
	{
		value: "4.Sistema acabados",
		label: "4.Sistema acabados",
	},
	{
		value: "5.Sistema de resguardo y protección de los usuarios",
		label: "5.Sistema de resguardo y protección de los usuarios",
	},
	{
		value: "6.Equipamiento",
		label: "6.Equipamiento",
	},
	{
		value: "7.Urbanización y áreas verdes",
		label: "7.Urbanización y áreas verdes",
	},
	{
		value: "8.Sistema de evacuación de aguas",
		label: "8.Sistema de evacuación de aguas",
	},
	{
		value: "9.Sistema de suministro de aguas",
		label: "9.Sistema de suministro de aguas",
	},
	{
		value: "10.Sistema de instalación eléctrica de AT",
		label: "10.Sistema de instalación eléctrica de AT",
	},
	{
		value: "11.Sistema de instalación eléctrica de BT",
		label: "11.Sistema de instalación eléctrica de BT",
	},
	{
		value: "12.Sistema de carga de vehículos eléctricos",
		label: "12.Sistema de carga de vehículos eléctricos",
	},
	{
		value: "13.Sistema de protección contra el rayo",
		label: "13.Sistema de protección contra el rayo",
	},
	{
		value: "14.Instalaciones térmicas de los edificios",
		label: "14.Instalaciones térmicas de los edificios",
	},
	{
		value: "15.Instalacion solar térmica",
		label: "15.Instalacion solar térmica",
	},
	{
		value: "16.Instalacion frigorífica",
		label: "16.Instalacion frigorífica",
	},
	{
		value: "17.Iluminación",
		label: "17.Iluminación",
	},
	{
		value: "18.Instalación de protección contra incendios",
		label: "18.Instalación de protección contra incendios",
	},
	{
		value: "19.Instalaciones petrolíferas",
		label: "19.Instalaciones petrolíferas",
	},
	{
		value: "20.Telecomunicaciones",
		label: "20.Telecomunicaciones",
	},
	{
		value: "21.Instalación de gases combustibles",
		label: "21.Instalación de gases combustibles",
	},
	{
		value: "22.Instalación de equipos a presión",
		label: "22.Instalación de equipos a presión",
	},
	{
		value: "23.Equipos de elevación",
		label: "23.Equipos de elevación",
	},
	{
		value: "24.Ascensores",
		label: "24.Medios de comunicación vertical",
	},
];
export const defaultValuesElementos = [
	{
		label: "Cimentación superficial / Cimentaciones profundas / Elementos de contención",
		value: "Cimentación superficial / Cimentaciones profundas / Elementos de contención",
		subsistemaEC: "Cimentación",
		sistemasEC: "1.Sistema estructural",
	},
	{
		label: "Pantallas y muros de contención",
		value: "Pantallas y muros de contención",
		subsistemaEC: "Cimentación",
		sistemasEC: "1.Sistema estructural",
	},
	{
		label: "Pilares y pantallas de hormigón",
		value: "Pilares y pantallas de hormigón",
		subsistemaEC: "Estructura vertical",
		sistemasEC: "1.Sistema estructural",
	},
	{
		label: "Uniones fijas y articulaciones de conexión a cimentación de acero",
		value: "Uniones fijas y articulaciones de conexión a cimentación de acero",
		subsistemaEC: "Estructura vertical",
		sistemasEC: "1.Sistema estructural",
	},
	{
		label: "Soportes y elementos compuestos de acero",
		value: "Soportes y elementos compuestos de acero",
		subsistemaEC: "Estructura vertical",
		sistemasEC: "1.Sistema estructural",
	},
	{
		label: "Muros de carga de fábrica",
		value: "Muros de carga de fábrica",
		subsistemaEC: "Estructura vertical",
		sistemasEC: "1.Sistema estructural",
	},
	{
		label: "Pilares y pilastras de fábrica",
		value: "Pilares y pilastras de fábrica",
		subsistemaEC: "Estructura vertical",
		sistemasEC: "1.Sistema estructural",
	},
	{
		label: "Vigas de hormigón",
		value: "Vigas de hormigón",
		subsistemaEC: "Estructura horizontal",
		sistemasEC: "1.Sistema estructural",
	},
	{
		label: "Forjados aligerados, losas y soleras de hormigón",
		value: "Forjados aligerados, losas y soleras de hormigón",
		subsistemaEC: "Estructura horizontal",
		sistemasEC: "1.Sistema estructural",
	},
	{
		label: "Uniones fijas y articulaciones de acero",
		value: "Uniones fijas y articulaciones de acero",
		subsistemaEC: "Estructura horizontal",
		sistemasEC: "1.Sistema estructural",
	},
	{
		label: "Vigas de acero",
		value: "Vigas de acero",
		subsistemaEC: "Estructura horizontal",
		sistemasEC: "1.Sistema estructural",
	},
	{
		label: "Forjados y entramados de acero",
		value: "Forjados y entramados de acero",
		subsistemaEC: "Estructura horizontal",
		sistemasEC: "1.Sistema estructural",
	},
	{
		label: "Escaleras de acero",
		value: "Escaleras de acero",
		subsistemaEC: "Estructura horizontal",
		sistemasEC: "1.Sistema estructural",
	},
	{
		label: "Nudos, canes y conexiones singulares de madera",
		value: "Nudos, canes y conexiones singulares de madera",
		subsistemaEC: "Estructura horizontal",
		sistemasEC: "1.Sistema estructural",
	},
	{
		label: "Vigas y pares de madera",
		value: "Vigas y pares de madera",
		subsistemaEC: "Estructura horizontal",
		sistemasEC: "1.Sistema estructural",
	},
	{
		label: "Parecillos y entablados de madera",
		value: "Parecillos y entablados de madera",
		subsistemaEC: "Estructura horizontal",
		sistemasEC: "1.Sistema estructural",
	},
	{
		label: "Uniones fijas y articulaciones de acero",
		value: "Uniones fijas y articulaciones de acero",
		subsistemaEC: "Estructura de cubiertas",
		sistemasEC: "1.Sistema estructural",
	},
	{
		label: "Estructuras trianguladas, mallas, tubulares, estructuras ligeras y correas de acero",
		value: "Estructuras trianguladas, mallas, tubulares, estructuras ligeras y correas de acero",
		subsistemaEC: "Estructura de cubiertas",
		sistemasEC: "1.Sistema estructural",
	},
	{
		label: "Nudos, canes y conexiones singulares de madera",
		value: "Nudos, canes y conexiones singulares de madera",
		subsistemaEC: "Estructura de cubiertas",
		sistemasEC: "1.Sistema estructural",
	},
	{
		label: "Vigas, rollizos y cerchas de madera",
		value: "Vigas, rollizos y cerchas de madera",
		subsistemaEC: "Estructura de cubiertas",
		sistemasEC: "1.Sistema estructural",
	},
	{
		label: "Pares, correas, parecillos y entablados de madera",
		value: "Pares, correas, parecillos y entablados de madera",
		subsistemaEC: "Estructura de cubiertas",
		sistemasEC: "1.Sistema estructural",
	},
	{
		label: "Rasillón sobre tabiquillos",
		value: "Rasillón sobre tabiquillos",
		subsistemaEC: "Cubiertas",
		sistemasEC: "2.Sistema envolvente",
	},
	{
		label: "Bóvedas de ladrillo",
		value: "Bóvedas de ladrillo",
		subsistemaEC: "Cubiertas",
		sistemasEC: "2.Sistema envolvente",
	},
	{
		label: "Tableros de ladrillo sobre estructura de madera",
		value: "Tableros de ladrillo sobre estructura de madera",
		subsistemaEC: "Cubiertas",
		sistemasEC: "2.Sistema envolvente",
	},
	{
		label: "Aislamiento",
		value: "Aislamiento",
		subsistemaEC: "Cubiertas",
		sistemasEC: "2.Sistema envolvente",
	},
	{
		label: "Impermeabilización",
		value: "Impermeabilización",
		subsistemaEC: "Cubiertas",
		sistemasEC: "2.Sistema envolvente",
	},
	{
		label: "Cobertura cerámica o pizarra",
		value: "Cobertura cerámica o pizarra",
		subsistemaEC: "Cubiertas",
		sistemasEC: "2.Sistema envolvente",
	},
	{
		label: "Cobertura fibrocemento",
		value: "Cobertura fibrocemento",
		subsistemaEC: "Cubiertas",
		sistemasEC: "2.Sistema envolvente",
	},
	{
		label: "Cobertura perfil metálico",
		value: "Cobertura perfil metálico",
		subsistemaEC: "Cubiertas",
		sistemasEC: "2.Sistema envolvente",
	},
	{
		label: "Elementos singulares ( chimeneas, juntas, lucernarios..)",
		value: "Elementos singulares ( chimeneas, juntas, lucernarios..)",
		subsistemaEC: "Cubiertas",
		sistemasEC: "2.Sistema envolvente",
	},
	{
		label: "Fachada de mampostería",
		value: "Fachada de mampostería",
		subsistemaEC: "Cerramientos y fachadas",
		sistemasEC: "2.Sistema envolvente",
	},
	{
		label: "Fachada de sillería",
		value: "Fachada de sillería",
		subsistemaEC: "Cerramientos y fachadas",
		sistemasEC: "2.Sistema envolvente",
	},
	{
		label: "Fachada de fábrica",
		value: "Fachada de fábrica",
		subsistemaEC: "Cerramientos y fachadas",
		sistemasEC: "2.Sistema envolvente",
	},
	{
		label: "Fachada panel prefabricado",
		value: "Fachada panel prefabricado",
		subsistemaEC: "Cerramientos y fachadas",
		sistemasEC: "2.Sistema envolvente",
	},
	{
		label: "Fachada panel metálico",
		value: "Fachada panel metálico",
		subsistemaEC: "Cerramientos y fachadas",
		sistemasEC: "2.Sistema envolvente",
	},
	{
		label: "Fachada con revoco",
		value: "Fachada con revoco",
		subsistemaEC: "Cerramientos y fachadas",
		sistemasEC: "2.Sistema envolvente",
	},
	{
		label: "Fachada con enfoscado",
		value: "Fachada con enfoscado",
		subsistemaEC: "Cerramientos y fachadas",
		sistemasEC: "2.Sistema envolvente",
	},
	{
		label: "Fachada mortero monocapa",
		value: "Fachada mortero monocapa",
		subsistemaEC: "Cerramientos y fachadas",
		sistemasEC: "2.Sistema envolvente",
	},
	{
		label: "Fachada aplacado cerámico",
		value: "Fachada aplacado cerámico",
		subsistemaEC: "Cerramientos y fachadas",
		sistemasEC: "2.Sistema envolvente",
	},
	{
		label: "Fachada chapado piedra",
		value: "Fachada chapado piedra",
		subsistemaEC: "Cerramientos y fachadas",
		sistemasEC: "2.Sistema envolvente",
	},
	{
		label: "Fachada chapado metálico",
		value: "Fachada chapado metálico",
		subsistemaEC: "Cerramientos y fachadas",
		sistemasEC: "2.Sistema envolvente",
	},
	{
		label: "Carpinterías de madera",
		value: "Carpinterías de madera",
		subsistemaEC: "Huecos y carpinterias exteriores",
		sistemasEC: "2.Sistema envolvente",
	},
	{
		label: "Carpinterías de acero",
		value: "Carpinterías de acero",
		subsistemaEC: "Huecos y carpinterias exteriores",
		sistemasEC: "2.Sistema envolvente",
	},
	{
		label: "Carpinterías de aluminio",
		value: "Carpinterías de aluminio",
		subsistemaEC: "Huecos y carpinterias exteriores",
		sistemasEC: "2.Sistema envolvente",
	},
	{
		label: "Carpinterías de PVC",
		value: "Carpinterías de PVC",
		subsistemaEC: "Huecos y carpinterias exteriores",
		sistemasEC: "2.Sistema envolvente",
	},
	{
		label: "Solera ventilada",
		value: "Solera ventilada",
		subsistemaEC: "Soleras y elementos contra el terreno",
		sistemasEC: "2.Sistema envolvente",
	},
	{
		label: "Forjado sanitario",
		value: "Forjado sanitario",
		subsistemaEC: "Soleras y elementos contra el terreno",
		sistemasEC: "2.Sistema envolvente",
	},
	{
		label: "Tabiquería de fábrica",
		value: "Tabiquería de fábrica",
		subsistemaEC: "Tabiquerías y particiones",
		sistemasEC: "3.Sistema de compartimentación",
	},
	{
		label: "Tabiquería ligera",
		value: "Tabiquería ligera",
		subsistemaEC: "Tabiquerías y particiones",
		sistemasEC: "3.Sistema de compartimentación",
	},
	{
		label: "Mamparas y particiones ligeras",
		value: "Mamparas y particiones ligeras",
		subsistemaEC: "Tabiquerías y particiones",
		sistemasEC: "3.Sistema de compartimentación",
	},
	{
		label: "Puertas de paso",
		value: "Puertas de paso",
		subsistemaEC: "Carpinterías interiores",
		sistemasEC: "3.Sistema de compartimentación",
	},
	{
		label: "Ventanas",
		value: "Ventanas",
		subsistemaEC: "Carpinterías interiores",
		sistemasEC: "3.Sistema de compartimentación",
	},
	{
		label: "Mosaico",
		value: "Mosaico",
		subsistemaEC: "Solados y pavimentos",
		sistemasEC: "4.Sistema acabados",
	},
	{
		label: "Continuo",
		value: "Continuo",
		subsistemaEC: "Solados y pavimentos",
		sistemasEC: "4.Sistema acabados",
	},
	{
		label: "Elevado (registrable o no registrable)",
		value: "Elevado (registrable o no registrable)",
		subsistemaEC: "Solados y pavimentos",
		sistemasEC: "4.Sistema acabados",
	},
	{
		label: "Continuo",
		value: "Continuo",
		subsistemaEC: "Paramentos",
		sistemasEC: "4.Sistema acabados",
	},
	{
		label: "Aplacados",
		value: "Aplacados",
		subsistemaEC: "Paramentos",
		sistemasEC: "4.Sistema acabados",
	},
	{
		label: "Raso",
		value: "Raso",
		subsistemaEC: "Techos",
		sistemasEC: "4.Sistema acabados",
	},
	{
		label: "Continuo suspendido",
		value: "Continuo suspendido",
		subsistemaEC: "Techos",
		sistemasEC: "4.Sistema acabados",
	},
	{
		label: "Modular suspendido",
		value: "Modular suspendido",
		subsistemaEC: "Techos",
		sistemasEC: "4.Sistema acabados",
	},
	{
		label: "Rejas",
		value: "Rejas",
		subsistemaEC: "Sistema de resguardos y protección",
		sistemasEC: "5.Sistema de resguardo y protección de los usuarios",
	},
	{
		label: "Vallas y cercados",
		value: "Vallas y cercados",
		subsistemaEC: "Sistema de resguardos y protección",
		sistemasEC: "5.Sistema de resguardo y protección de los usuarios",
	},
	{
		label: "Barandillas y pasamanos",
		value: "Barandillas y pasamanos",
		subsistemaEC: "Sistema de resguardos y protección",
		sistemasEC: "5.Sistema de resguardo y protección de los usuarios",
	},
	{
		label: "de Oficina",
		value: "de Oficina",
		subsistemaEC: "Mobiliario",
		sistemasEC: "6.Equipamiento",
	},
	{
		label: "Auxiliar",
		value: "Auxiliar",
		subsistemaEC: "Mobiliario",
		sistemasEC: "6.Equipamiento",
	},
	{
		label: "Elementos de almacenaje",
		value: "Elementos de almacenaje",
		subsistemaEC: "Mobiliario",
		sistemasEC: "6.Equipamiento",
	},
	{
		label: "de Urbanización",
		value: "de Urbanización",
		subsistemaEC: "Mobiliario",
		sistemasEC: "6.Equipamiento",
	},
	{
		label: "Interior",
		value: "Interior",
		subsistemaEC: "Señalización y señalética",
		sistemasEC: "6.Equipamiento",
	},
	{
		label: "Exterior",
		value: "Exterior",
		subsistemaEC: "Señalización y señalética",
		sistemasEC: "6.Equipamiento",
	},
	{
		label: "Verticales exteriores",
		value: "Verticales exteriores",
		subsistemaEC: "Señalización y señalética",
		sistemasEC: "6.Equipamiento",
	},
	{
		label: "Horizontales exteriores",
		value: "Horizontales exteriores",
		subsistemaEC: "Señalización y señalética",
		sistemasEC: "6.Equipamiento",
	},
	{
		label: "Soportes y bancos de trabajo",
		value: "Soportes y bancos de trabajo",
		subsistemaEC: "Equipamiento industrial",
		sistemasEC: "6.Equipamiento",
	},
	{
		label: "Equipos auxiliares",
		value: "Equipos auxiliares",
		subsistemaEC: "Equipamiento industrial",
		sistemasEC: "6.Equipamiento",
	},
	{
		label: "Elementos de almacenaje",
		value: "Elementos de almacenaje",
		subsistemaEC: "Equipamiento industrial",
		sistemasEC: "6.Equipamiento",
	},
	{
		label: "Lavabo",
		value: "Lavabo",
		subsistemaEC: "Mobiliario aseos",
		sistemasEC: "6.Equipamiento",
	},
	{
		label: "Ducha/Bañera",
		value: "Ducha/Bañera",
		subsistemaEC: "Mobiliario aseos",
		sistemasEC: "6.Equipamiento",
	},
	{
		label: "Inodoro",
		value: "Inodoro",
		subsistemaEC: "Mobiliario aseos",
		sistemasEC: "6.Equipamiento",
	},
	{
		label: "Bidé",
		value: "Bidé",
		subsistemaEC: "Mobiliario aseos",
		sistemasEC: "6.Equipamiento",
	},
	{
		label: "Barras fijas y móviles",
		value: "Barras fijas y móviles",
		subsistemaEC: "Equipamiento accesibilidad",
		sistemasEC: "6.Equipamiento",
	},
	{
		label: "Equipamiento auxiliar de baños y aseos",
		value: "Equipamiento auxiliar de baños y aseos",
		subsistemaEC: "Equipamiento accesibilidad",
		sistemasEC: "6.Equipamiento",
	},
	{
		label: "Llamadores y sistemas de interconexion",
		value: "Llamadores y sistemas de interconexion",
		subsistemaEC: "Equipamiento accesibilidad",
		sistemasEC: "6.Equipamiento",
	},
	{
		label: "Rampas y elementos portátiles",
		value: "Rampas y elementos portátiles",
		subsistemaEC: "Equipamiento accesibilidad",
		sistemasEC: "6.Equipamiento",
	},
	{
		label: "Pavimento asfáltico",
		value: "Pavimento asfáltico",
		subsistemaEC: "Pavimentos para accesos rodados",
		sistemasEC: "7.Urbanización y áreas verdes",
	},
	{
		label: "Hormigón contra el terreno",
		value: "Hormigón contra el terreno",
		subsistemaEC: "Pavimentos para accesos rodados",
		sistemasEC: "7.Urbanización y áreas verdes",
	},
	{
		label: "Material compactado",
		value: "Material compactado",
		subsistemaEC: "Pavimentos para accesos rodados",
		sistemasEC: "7.Urbanización y áreas verdes",
	},
	{
		label: "Terreno natural",
		value: "Terreno natural",
		subsistemaEC: "Pavimentos para accesos rodados",
		sistemasEC: "7.Urbanización y áreas verdes",
	},
	{
		label: "Hormigón contra el terreno",
		value: "Hormigón contra el terreno",
		subsistemaEC: "Pavimentos y solados peatonales",
		sistemasEC: "7.Urbanización y áreas verdes",
	},
	{
		label: "Baldosas prefabricadas",
		value: "Baldosas prefabricadas",
		subsistemaEC: "Pavimentos y solados peatonales",
		sistemasEC: "7.Urbanización y áreas verdes",
	},
	{
		label: "Material compactado",
		value: "Material compactado",
		subsistemaEC: "Pavimentos y solados peatonales",
		sistemasEC: "7.Urbanización y áreas verdes",
	},
	{
		label: "Pavimento asfáltico",
		value: "Pavimento asfáltico",
		subsistemaEC: "Pavimentos y solados peatonales",
		sistemasEC: "7.Urbanización y áreas verdes",
	},
	{
		label: "Terreno natural",
		value: "Terreno natural",
		subsistemaEC: "Pavimentos y solados peatonales",
		sistemasEC: "7.Urbanización y áreas verdes",
	},
	{
		label: "Escaleras de hormigón armado",
		value: "Escaleras de hormigón armado",
		subsistemaEC: "Estructuras auxiliares y de acceso",
		sistemasEC: "7.Urbanización y áreas verdes",
	},
	{
		label: "Rampas de hormigón",
		value: "Rampas de hormigón",
		subsistemaEC: "Estructuras auxiliares y de acceso",
		sistemasEC: "7.Urbanización y áreas verdes",
	},
	{
		label: "Muretes y cunetas de hormigón",
		value: "Muretes y cunetas de hormigón",
		subsistemaEC: "Estructuras auxiliares y de acceso",
		sistemasEC: "7.Urbanización y áreas verdes",
	},
	{
		label: "Encintados prefabricados",
		value: "Encintados prefabricados",
		subsistemaEC: "Estructuras auxiliares y de acceso",
		sistemasEC: "7.Urbanización y áreas verdes",
	},
	{
		label: "Voladizos y viseras metálicas",
		value: "Voladizos y viseras metálicas",
		subsistemaEC: "Estructuras ligeras",
		sistemasEC: "7.Urbanización y áreas verdes",
	},
	{
		label: "Marquesinas metálicas",
		value: "Marquesinas metálicas",
		subsistemaEC: "Estructuras ligeras",
		sistemasEC: "7.Urbanización y áreas verdes",
	},
	{
		label: "Escaleras y escalas metálicas",
		value: "Escaleras y escalas metálicas",
		subsistemaEC: "Estructuras ligeras",
		sistemasEC: "7.Urbanización y áreas verdes",
	},
	{
		label: "Acometida",
		value: "Acometida",
		subsistemaEC: "Elementos enterrados",
		sistemasEC: "8.Sistema de evacuación de aguas",
	},
	{
		label: "Fosa séptica",
		value: "Fosa séptica",
		subsistemaEC: "Elementos enterrados",
		sistemasEC: "8.Sistema de evacuación de aguas",
	},
	{
		label: "Arquetas",
		value: "Arquetas",
		subsistemaEC: "Elementos enterrados",
		sistemasEC: "8.Sistema de evacuación de aguas",
	},
	{
		label: "Colectores Enterrados",
		value: "Colectores Enterrados",
		subsistemaEC: "Elementos enterrados",
		sistemasEC: "8.Sistema de evacuación de aguas",
	},
	{
		label: "Colectores vistos",
		value: "Colectores vistos",
		subsistemaEC: "Canalizaciones horizontales",
		sistemasEC: "8.Sistema de evacuación de aguas",
	},
	{
		label: "Bomba de elevación",
		value: "Bomba de elevación",
		subsistemaEC: "Otros elementos del sistema",
		sistemasEC: "8.Sistema de evacuación de aguas",
	},
	{
		label: "Sifones",
		value: "Sifones",
		subsistemaEC: "Otros elementos del sistema",
		sistemasEC: "8.Sistema de evacuación de aguas",
	},
	{
		label: "Imbornal",
		value: "Imbornal",
		subsistemaEC: "Otros elementos del sistema",
		sistemasEC: "8.Sistema de evacuación de aguas",
	},
	{
		label: "Acometida",
		value: "Acometida",
		subsistemaEC: "Elementos de distribución",
		sistemasEC: "9.Sistema de suministro de aguas",
	},
	{
		label: "Contadores",
		value: "Contadores",
		subsistemaEC: "Elementos de distribución",
		sistemasEC: "9.Sistema de suministro de aguas",
	},
	{
		label: "Grupo de presión",
		value: "Grupo de presión",
		subsistemaEC: "Elementos de distribución",
		sistemasEC: "9.Sistema de suministro de aguas",
	},
	{
		label: "Depósitos AFS",
		value: "Depósitos AFS",
		subsistemaEC: "Depósitos de acumulación",
		sistemasEC: "9.Sistema de suministro de aguas",
	},
	{
		label: "Depósitos ACS",
		value: "Depósitos ACS",
		subsistemaEC: "Depósitos de acumulación",
		sistemasEC: "9.Sistema de suministro de aguas",
	},
	{
		label: "Tuberías AFS",
		value: "Tuberías AFS",
		subsistemaEC: "Canalizaciones",
		sistemasEC: "9.Sistema de suministro de aguas",
	},
	{
		label: "Tuberías ACS",
		value: "Tuberías ACS",
		subsistemaEC: "Canalizaciones",
		sistemasEC: "9.Sistema de suministro de aguas",
	},
	{
		label: "Montantes",
		value: "Montantes",
		subsistemaEC: "Canalizaciones",
		sistemasEC: "9.Sistema de suministro de aguas",
	},
	{
		label: "Válvulas y llaves",
		value: "Válvulas y llaves",
		subsistemaEC: "Otros elementos",
		sistemasEC: "9.Sistema de suministro de aguas",
	},
	{
		label: "Mecanismos",
		value: "Mecanismos",
		subsistemaEC: "Otros elementos",
		sistemasEC: "9.Sistema de suministro de aguas",
	},
	{
		label: "Equipos de cloración",
		value: "Equipos de cloración",
		subsistemaEC: "Otros elementos",
		sistemasEC: "9.Sistema de suministro de aguas",
	},
	{
		label: "Acometida eléctrica AT",
		value: "Acometidas",
		subsistemaEC: "Elementos de distribución",
		sistemasEC: "10.Sistema de instalación eléctrica de AT",
	},
	{
		label: "Centro de transformación",
		value: "Centro de transformación",
		subsistemaEC: "Elementos de distribución",
		sistemasEC: "10.Sistema de instalación eléctrica de AT",
	},
	{
		label: "Red de tierras AT",
		value: "Red de tierrras AT",
		subsistemaEC: "Elementos de distribución",
		sistemasEC: "10.Sistema de instalación eléctrica de AT",
	},
	{
		label: "Acometida eléctrica BT",
		value: "Contadores",
		subsistemaEC: "Elementos de distribución",
		sistemasEC: "11.Sistema de instalación eléctrica de BT",
	},
	{
		label: "Contadores",
		value: "Contadores",
		subsistemaEC: "Elementos de distribución",
		sistemasEC: "11.Sistema de instalación eléctrica de BT",
	},
	{
		label: "Cuadro eléctrico",
		value: "Cuadros eléctricos",
		subsistemaEC: "Elementos de distribución",
		sistemasEC: "11.Sistema de instalación eléctrica de BT",
	},
	{
		label: "Red de tierras BT",
		value: "Red de tierras BT",
		subsistemaEC: "Elementos de distribución",
		sistemasEC: "11.Sistema de instalación eléctrica de BT",
	},
	{
		label: "Red distribución BT",
		value: "Red distribución BT",
		subsistemaEC: "Elementos de distribución",
		sistemasEC: "11.Sistema de instalación eléctrica de BT",
	},
	{
		label: "Distribución y canalizaciones BT",
		value: "Distribución y canalizaciones BT",
		subsistemaEC: "Elementos de distribución",
		sistemasEC: "11.Sistema de instalación eléctrica de BT",
	},
	{
		label: "Subcuadros",
		value: "Subcuadros",
		subsistemaEC: "Elementos de distribución",
		sistemasEC: "11.Sistema de instalación eléctrica de BT",
	},
	{
		label: "Tomas de corriente",
		value: "Tomas de corriente",
		subsistemaEC: "Elementos de distribución",
		sistemasEC: "11.Sistema de instalación eléctrica de BT",
	},
	// {
	// 	label: "Tomas de tierra",
	// 	value: "Tomas de tierra",
	// 	subsistemaEC: "Elementos de distribución",
	// 	sistemasEC: "11.Sistema de instalación eléctrica de BT",
	// },
	{
		label: "Batería de condensadores",
		value: "Batería de condensadores",
		subsistemaEC: "Elementos de distribución",
		sistemasEC: "11.Sistema de instalación eléctrica de BT",
	},
	{
		label: "Sistema de alimentación ininterrumpida (SAI)",
		value: "Sistema de alimentación ininterrumpida (SAI)",
		subsistemaEC: "Elementos de distribución",
		sistemasEC: "11.Sistema de instalación eléctrica de BT",
	},
	{
		label: "Grupo electrógeno",
		value: "Grupo electrógeno",
		subsistemaEC: "Elementos de distribución",
		sistemasEC: "11.Sistema de instalación eléctrica de BT",
	},
	{
		label: "Caja general de Protección (CGP)",
		value: "Caja general de Protección (CGP)",
		subsistemaEC: "Elementos de protección",
		sistemasEC: "11.Sistema de instalación eléctrica de BT",
	},
	{
		label: "Interruptor diferencial",
		value: "Interruptor diferencial",
		subsistemaEC: "Elementos de protección",
		sistemasEC: "11.Sistema de instalación eléctrica de BT",
	},
	{
		label: "Interruptor automático",
		value: "Interruptor automático",
		subsistemaEC: "Elementos de protección",
		sistemasEC: "11.Sistema de instalación eléctrica de BT",
	},
	{
		label: "Fusibles",
		value: "Fusibles",
		subsistemaEC: "Elementos de protección",
		sistemasEC: "11.Sistema de instalación eléctrica de BT",
	},
	{
		label: "Equipos generadores de calor",
		value: "Equipos generadores de calor",
		subsistemaEC: "Calefacción",
		sistemasEC: "14.Instalaciones térmicas de los edificios",
	},
	{
		label: "Equipos emisores de calor",
		value: "Equipos emisores de calor",
		subsistemaEC: "Calefacción",
		sistemasEC: "14.Instalaciones térmicas de los edificios",
	},
	{
		label: "Equipos de bombeo",
		value: "Equipos de bombeo",
		subsistemaEC: "Calefacción",
		sistemasEC: "14.Instalaciones térmicas de los edificios",
	},
	{
		label: "Equipos generadores de frío",
		value: "Equipos generadores de frío",
		subsistemaEC: "Refrigeración",
		sistemasEC: "14.Instalaciones térmicas de los edificios",
	},
	{
		label: "Equipos emisores de frío",
		value: "Equipos emisores de frío",
		subsistemaEC: "Refrigeración",
		sistemasEC: "14.Instalaciones térmicas de los edificios",
	},
	{
		label: "Equipo de ventilación",
		value: "Equipo de ventilación",
		subsistemaEC: "Ventilación",
		sistemasEC: "14.Instalaciones térmicas de los edificios",
	},
	{
		label: "Recuperador de calor",
		value: "Recuperador de calor",
		subsistemaEC: "Ventilación",
		sistemasEC: "14.Instalaciones térmicas de los edificios",
	},
	{
		label: "Equipo recuperador de calor",
		value: "Equipo recuperador de calor",
		subsistemaEC: "Recuperador de calor",
		sistemasEC: "14.Instalaciones térmicas de los edificios",
	},
	{
		label: "Equipo de cogeneración",
		value: "Equipo de cogeneración",
		subsistemaEC: "Cogeneración",
		sistemasEC: "14.Instalaciones térmicas de los edificios",
	},
	{
		label: "Captadores",
		value: "Captadores",
		subsistemaEC: "Sistema de captación",
		sistemasEC: "15.Instalacion solar térmica",
	},
	{
		label: "Canalizaciones",
		value: "Canalizaciones",
		subsistemaEC: "Sistema de distribución",
		sistemasEC: "15.Instalacion solar térmica",
	},
	{
		label: "Intercambiador",
		value: "Intercambiador",
		subsistemaEC: "Sistema de distribución",
		sistemasEC: "15.Instalacion solar térmica",
	},
	{
		label: "Acumuladores",
		value: "Acumuladores",
		subsistemaEC: "Sistema de acumulación",
		sistemasEC: "15.Instalacion solar térmica",
	},
	{
		label: "Energía convencional auxiliar",
		value: "Energía convencional auxiliar",
		subsistemaEC: "Sistema de apoyo",
		sistemasEC: "15.Instalacion solar térmica",
	},
	{
		label: "Elementos de control",
		value: "Elementos de control",
		subsistemaEC: "Sistema de apoyo",
		sistemasEC: "15.Instalacion solar térmica",
	},
	{
		label: "Fluorescente compacta",
		value: "Fluorescente compacta",
		subsistemaEC: "Iluminación y focos",
		sistemasEC: "17.Iluminación",
	},
	{
		label: "Fluorescente tubular",
		value: "Fluorescente tubular",
		subsistemaEC: "Iluminación y focos",
		sistemasEC: "17.Iluminación",
	},
	{
		label: "Halogenuros metálicos",
		value: "Halogenuros metálicos",
		subsistemaEC: "Iluminación y focos",
		sistemasEC: "17.Iluminación",
	},
	{
		label: "Incandescente convencional",
		value: "Incandescente convencional",
		subsistemaEC: "Iluminación y focos",
		sistemasEC: "17.Iluminación",
	},
	{
		label: "Incandescente halógena",
		value: "Incandescente halógena",
		subsistemaEC: "Iluminación y focos",
		sistemasEC: "17.Iluminación",
	},
	{
		label: "LED",
		value: "LED",
		subsistemaEC: "Iluminación y focos",
		sistemasEC: "17.Iluminación",
	},
	{
		label: "Vapor de mercurio",
		value: "Vapor de mercurio",
		subsistemaEC: "Iluminación y focos",
		sistemasEC: "17.Iluminación",
	},
	{
		label: "Vapor de sodio",
		value: "Vapor de sodio",
		subsistemaEC: "Iluminación y focos",
		sistemasEC: "17.Iluminación",
	},
	{
		label: "Interruptores",
		value: "Interruptores",
		subsistemaEC: "Elementos de control y encendido",
		sistemasEC: "17.Iluminación",
	},
	{
		label: "Alumbrado de emergencia",
		value: "Alumbrado de emergencia",
		subsistemaEC: "Alumbrado y señalética de emergencia",
		sistemasEC: "17.Iluminación",
	},
	{
		label: "BIE",
		value: "BIE",
		subsistemaEC: "Protección activa",
		sistemasEC: "18.Instalación de protección contra incendios",
	},
	{
		label: "Columna seca",
		value: "Columna seca",
		subsistemaEC: "Protección activa",
		sistemasEC: "18.Instalación de protección contra incendios",
	},
	{
		label: "Extintor",
		value: "Extintor",
		subsistemaEC: "Protección activa",
		sistemasEC: "18.Instalación de protección contra incendios",
	},
	{
		label: "Hidrante",
		value: "Hidrante",
		subsistemaEC: "Protección activa",
		sistemasEC: "18.Instalación de protección contra incendios",
	},
	{
		label: "Detectores",
		value: "Detectores",
		subsistemaEC: "Protección activa",
		sistemasEC: "18.Instalación de protección contra incendios",
	},
	{
		label: "Otros elementos",
		value: "Otros elementos",
		subsistemaEC: "Protección activa",
		sistemasEC: "18.Instalación de protección contra incendios",
	},
	{
		label: "Elementos de protección pasiva",
		value: "Elementos de protección pasiva",
		subsistemaEC: "Protección pasiva",
		sistemasEC: "18.Instalación de protección contra incendios",
	},
	{
		label: "Otro",
		value: "Otro",
	},
];
export const defaultValuesJefaturas = [
	{
		label: "Jef.Base. Brihuega-AV",
		value: "Jef.Base. Brihuega-AV",
		parent: "G.Mto.A.V. Noreste",
	},
	{
		label: "Jef.Base. Calatayud-AV",
		value: "Jef.Base. Calatayud-AV",
		parent: "G.Mto.A.V. Noreste",
	},
	{
		label: "Jef.Base. Montagut-AV",
		value: "Jef.Base. Montagut-AV",
		parent: "G.Mto.A.V. Noreste",
	},
	{
		label: "Jef.Base. San Feliu de Buixalleu-AV",
		value: "Jef.Base. San Feliu de Buixalleu-AV",
		parent: "G.Mto.A.V. Noreste",
	},
	{
		label: "Jef.Base. Villafranca del Penedes-AV",
		value: "Jef.Base. Villafranca del Penedes-AV",
		parent: "G.Mto.A.V. Noreste",
	},
	{
		label: "Jefatura Premantenimiento O Irixo",
		value: "Jefatura Premantenimiento O Irixo",
		parent: "G.Mto.A.V. Noroeste",
	},
	{
		label: "Jef.Base. La Hiniesta-AV",
		value: "Jef.Base. La Hiniesta-AV",
		parent: "G.Mto.A.V. Norte",
	},
	{
		label: "Jef.Base. Olmedo-AV",
		value: "Jef.Base. Olmedo-AV",
		parent: "G.Mto.A.V. Norte",
	},
	{
		label: "Jef.Base. Villada-AV",
		value: "Jef.Base. Villada-AV",
		parent: "G.Mto.A.V. Norte",
	},
	{
		label: "Jef.Base. Gabaldon-AV",
		value: "Jef.Base. Gabaldon-AV",
		parent: "G.Mto.A.V. Este",
	},
	{
		label: "Jef.Base. Monforte del Cid-AV",
		value: "Jef.Base. Monforte del Cid-AV",
		parent: "G.Mto.A.V. Este",
	},
	{
		label: "Jef.Base. Requena-AV",
		value: "Jef.Base. Requena-AV",
		parent: "G.Mto.A.V. Este",
	},
	{
		label: "Jef.Base. Villarubia-AV",
		value: "Jef.Base. Villarubia-AV",
		parent: "G.Mto.A.V. Este",
	},
	{
		label: "Jef.Base. Antequera-AV",
		value: "Jef.Base. Antequera-AV",
		parent: "G.Mto.A.V.Sur",
	},
	{
		label: "Jef.Base. Calatrava-AV",
		value: "Jef.Base. Calatrava-AV",
		parent: "G.Mto.A.V.Sur",
	},
	{
		label: "Jef.Base. Hornachuelos-AV",
		value: "Jef.Base. Hornachuelos-AV",
		parent: "G.Mto.A.V.Sur",
	},
	{
		label: "Jef.Base. Mora-AV",
		value: "Jef.Base. Mora-AV",
		parent: "G.Mto.A.V.Sur",
	},
	{
		label: "Señalizacion - GEA - AV",
		value: "Señalizacion - GEA - AV",
		parent: "G.Mto.A.V.Sur",
	},
	{
		label: "Telecomunicaciones - GEA - AV",
		value: "Telecomunicaciones - GEA - AV",
		parent: "G.Mto.A.V.Sur",
	},
	{
		label: "J.A.M. BARCELONA",
		value: "J.A.M. BARCELONA",
		parent: "RC - Subdirección NORESTE",
	},
	{
		label: "J.A.M. ZARAGOZA",
		value: "J.A.M. ZARAGOZA",
		parent: "RC - Subdirección NORESTE",
	},
	{
		label: "Cabecera Gerencia Noreste",
		value: "Cabecera Gerencia Noreste",
		parent: "RC - Subdirección NORESTE",
	},
	{
		label: "Jef.Coord.Inver.Noreste",
		value: "Jef.Coord.Inver.Noreste",
		parent: "RC - Subdirección NORESTE",
	},
	{
		label: "J.A.M. BURGOS",
		value: "J.A.M. BURGOS",
		parent: "RC - Subdirección NORTE",
	},
	{
		label: "J.A.M. VALLADOLID",
		value: "J.A.M. VALLADOLID",
		parent: "RC - Subdirección NORTE",
	},
	{ label: "J.A.M. PAMPLONA", value: "J.M. IRÚN", parent: "RC - Subdirección NORTE" },
	{
		label: "Cabecera Gerencia Norte",
		value: "Cabecera Gerencia Norte",
		parent: "RC - Subdirección NORTE",
	},
	{
		label: "Jef.Coord.Inver.Norte",
		value: "Jef.Coord.Inver.Norte",
		parent: "RC - Subdirección NORTE",
	},
	{
		label: "J.A.M. CÓRDOBA",
		value: "J.A.M. CÓRDOBA",
		parent: "RC - Subdirección SUR",
	},
	{
		label: "J.A.M. SEVILLA",
		value: "J.A.M. SEVILLA",
		parent: "RC - Subdirección SUR",
	},
	{
		label: "J.M. LINARES-BAEZA",
		value: "J.M. LINARES-BAEZA",
		parent: "RC - Subdirección SUR",
	},
	{ label: "J.M. MÉRIDA", value: "J.M. MÉRIDA", parent: "RC - Subdirección SUR" },
	{
		label: "Cabecera Gerencia Sur",
		value: "Cabecera Gerencia Sur",
		parent: "RC - Subdirección SUR",
	},
	{
		label: "Jef.Coord.Inver.Sur",
		value: "Jef.Coord.Inver.Sur",
		parent: "RC - Subdirección SUR",
	},
	{
		label: "J.A.M. VALENCIA",
		value: "J.A.M. VALENCIA",
		parent: "RC - Subdirección ESTE",
	},
	{ label: "J.M. ALBACETE", value: "J.M. ALBACETE", parent: "RC - Subdirección ESTE" },
	{
		label: "Cabecera Gerencia Este",
		value: "Cabecera Gerencia Este",
		parent: "RC - Subdirección ESTE",
	},
	{
		label: "Jef.Coord.Inver.Este",
		value: "Jef.Coord.Inver.Este",
		parent: "RC - Subdirección ESTE",
	},
	{
		label: "J.A.M. MADRID-NORTE",
		value: "J.A.M. MADRID-NORTE",
		parent: "RC - Subdirección CENTRO",
	},
	{
		label: "J.A.M. MADRID-SUR",
		value: "J.A.M. MADRID-SUR",
		parent: "RC - Subdirección CENTRO",
	},
	{
		label: "Cabecera Gerencia Centro",
		value: "Cabecera Gerencia Centro",
		parent: "RC - Subdirección CENTRO",
	},
	{
		label: "Jef.Coord.Inver.Centro",
		value: "Jef.Coord.Inver.Centro",
		parent: "RC - Subdirección CENTRO",
	},
	{
		label: "J.A.M. ORENSE",
		value: "J.A.M. ORENSE",
		parent: "RC - Subdirección NOROESTE",
	},
	{ label: "J.A.M. LEÓN", value: "J.A.M. LEÓN", parent: "RC - Subdirección NOROESTE" },
	{
		label: "J.M. SALAMANCA",
		value: "J.M. SALAMANCA",
		parent: "RC - Subdirección NOROESTE",
	},
	{
		label: "Cabecera Gerencia Noroeste",
		value: "Cabecera Gerencia Noroeste",
		parent: "RC - Subdirección NOROESTE",
	},
	{
		label: "Jef.Coord.Inver.Noroeste",
		value: "Jef.Coord.Inver.Noroeste",
		parent: "RC - Subdirección NOROESTE",
	},
	{
		label: "Otro",
		value: "Otro",
		parent: "Otro",
	},
];
export const rawDefaults: Record<string, (string | Choice)[]> = {
	ambito: [
		{ label: "AV - Todos los ejes", value: "AV - Todos los ejes" },
		{
			label: "G.Mto.A.V. Este",
			value: "G.Mto.A.V. Este",
			red: "AV",
		},
		{
			label: "G.Mto.A.V. Norte",
			value: "G.Mto.A.V. Norte",
			red: "AV",
		},
		{
			label: "G.Mto.A.V. Noreste",
			value: "G.Mto.A.V. Noreste",
			red: "AV",
		},
		{
			label: "G.Mto.A.V. Noroeste",
			value: "G.Mto.A.V. Noroeste",
			red: "AV",
		},
		{
			label: "G.Mto.A.V.Sur",
			value: "G.Mto.A.V.Sur",
			red: "AV",
		},
		{
			label: "RC - Todas las subdirecciones",
			value: "RC - Todas las subdirecciones",
		},
		{
			label: "RC - Subdirección CENTRO",
			value: "RC - Subdirección CENTRO",
			red: "RC",
		},
		{
			label: "RC - Subdirección ESTE",
			value: "RC - Subdirección ESTE",
			red: "RC",
		},
		{
			label: "RC - Subdirección NORESTE",
			value: "RC - Subdirección NORESTE",
			red: "RC",
		},
		{
			label: "RC - Subdirección NOROESTE",
			value: "RC - Subdirección NOROESTE",
			red: "RC",
		},
		{
			label: "RC - Subdirección NORTE",
			value: "RC - Subdirección NORTE",
			red: "RC",
		},
		{
			label: "RC - Subdirección SUR",
			value: "RC - Subdirección SUR",
			red: "RC",
		},
		{
			label: "Otro",
			value: "Otro",
		},
	],
	jefatura: [
		{
			label: "Jef.Base. Brihuega-AV",
			value: "Jef.Base. Brihuega-AV",
			parent: "G.Mto.A.V. Noreste",
		},
		{
			label: "Jef.Base. Calatayud-AV",
			value: "Jef.Base. Calatayud-AV",
			parent: "G.Mto.A.V. Noreste",
		},
		{
			label: "Jef.Base. Montagut-AV",
			value: "Jef.Base. Montagut-AV",
			parent: "G.Mto.A.V. Noreste",
		},
		{
			label: "Jef.Base. San Feliu de Buixalleu-AV",
			value: "Jef.Base. San Feliu de Buixalleu-AV",
			parent: "G.Mto.A.V. Noreste",
		},
		{
			label: "Jef.Base. Villafranca del Penedes-AV",
			value: "Jef.Base. Villafranca del Penedes-AV",
			parent: "G.Mto.A.V. Noreste",
		},
		{
			label: "Jefatura Premantenimiento O Irixo",
			value: "Jefatura Premantenimiento O Irixo",
			parent: "G.Mto.A.V. Noroeste",
		},
		{
			label: "Jef.Base. La Hiniesta-AV",
			value: "Jef.Base. La Hiniesta-AV",
			parent: "G.Mto.A.V. Norte",
		},
		{
			label: "Jef.Base. Olmedo-AV",
			value: "Jef.Base. Olmedo-AV",
			parent: "G.Mto.A.V. Norte",
		},
		{
			label: "Jef.Base. Villada-AV",
			value: "Jef.Base. Villada-AV",
			parent: "G.Mto.A.V. Norte",
		},
		{
			label: "Jef.Base. Gabaldon-AV",
			value: "Jef.Base. Gabaldon-AV",
			parent: "G.Mto.A.V. Este",
		},
		{
			label: "Jef.Base. Monforte del Cid-AV",
			value: "Jef.Base. Monforte del Cid-AV",
			parent: "G.Mto.A.V. Este",
		},
		{
			label: "Jef.Base. Requena-AV",
			value: "Jef.Base. Requena-AV",
			parent: "G.Mto.A.V. Este",
		},
		{
			label: "Jef.Base. Villarubia-AV",
			value: "Jef.Base. Villarubia-AV",
			parent: "G.Mto.A.V. Este",
		},
		{
			label: "Jef.Base. Antequera-AV",
			value: "Jef.Base. Antequera-AV",
			parent: "G.Mto.A.V.Sur",
		},
		{
			label: "Jef.Base. Calatrava-AV",
			value: "Jef.Base. Calatrava-AV",
			parent: "G.Mto.A.V.Sur",
		},
		{
			label: "Jef.Base. Hornachuelos-AV",
			value: "Jef.Base. Hornachuelos-AV",
			parent: "G.Mto.A.V.Sur",
		},
		{
			label: "Jef.Base. Mora-AV",
			value: "Jef.Base. Mora-AV",
			parent: "G.Mto.A.V.Sur",
		},
		{
			label: "Señalizacion - GEA - AV",
			value: "Señalizacion - GEA - AV",
			parent: "G.Mto.A.V.Sur",
		},
		{
			label: "Telecomunicaciones - GEA - AV",
			value: "Telecomunicaciones - GEA - AV",
			parent: "G.Mto.A.V.Sur",
		},
		{
			label: "J.A.M. BARCELONA",
			value: "J.A.M. BARCELONA",
			parent: "RC - Subdirección NORESTE",
		},
		{
			label: "J.A.M. ZARAGOZA",
			value: "J.A.M. ZARAGOZA",
			parent: "RC - Subdirección NORESTE",
		},
		{
			label: "Cabecera Gerencia Noreste",
			value: "Cabecera Gerencia Noreste",
			parent: "RC - Subdirección NORESTE",
		},
		{
			label: "Jef.Coord.Inver.Noreste",
			value: "Jef.Coord.Inver.Noreste",
			parent: "RC - Subdirección NORESTE",
		},
		{
			label: "J.A.M. BURGOS",
			value: "J.A.M. BURGOS",
			parent: "RC - Subdirección NORTE",
		},
		{
			label: "J.A.M. VALLADOLID",
			value: "J.A.M. VALLADOLID",
			parent: "RC - Subdirección NORTE",
		},
		{ label: "J.A.M. PAMPLONA", value: "J.M. IRÚN", parent: "RC - Subdirección NORTE" },
		{
			label: "Cabecera Gerencia Norte",
			value: "Cabecera Gerencia Norte",
			parent: "RC - Subdirección NORTE",
		},
		{
			label: "Jef.Coord.Inver.Norte",
			value: "Jef.Coord.Inver.Norte",
			parent: "RC - Subdirección NORTE",
		},
		{
			label: "J.A.M. CÓRDOBA",
			value: "J.A.M. CÓRDOBA",
			parent: "RC - Subdirección SUR",
		},
		{
			label: "J.A.M. SEVILLA",
			value: "J.A.M. SEVILLA",
			parent: "RC - Subdirección SUR",
		},
		{
			label: "J.M. LINARES-BAEZA",
			value: "J.M. LINARES-BAEZA",
			parent: "RC - Subdirección SUR",
		},
		{ label: "J.M. MÉRIDA", value: "J.M. MÉRIDA", parent: "RC - Subdirección SUR" },
		{
			label: "Cabecera Gerencia Sur",
			value: "Cabecera Gerencia Sur",
			parent: "RC - Subdirección SUR",
		},
		{
			label: "Jef.Coord.Inver.Sur",
			value: "Jef.Coord.Inver.Sur",
			parent: "RC - Subdirección SUR",
		},
		{
			label: "J.A.M. VALENCIA",
			value: "J.A.M. VALENCIA",
			parent: "RC - Subdirección ESTE",
		},
		{ label: "J.M. ALBACETE", value: "J.M. ALBACETE", parent: "RC - Subdirección ESTE" },
		{
			label: "Cabecera Gerencia Este",
			value: "Cabecera Gerencia Este",
			parent: "RC - Subdirección ESTE",
		},
		{
			label: "Jef.Coord.Inver.Este",
			value: "Jef.Coord.Inver.Este",
			parent: "RC - Subdirección ESTE",
		},
		{
			label: "J.A.M. MADRID-NORTE",
			value: "J.A.M. MADRID-NORTE",
			parent: "RC - Subdirección CENTRO",
		},
		{
			label: "J.A.M. MADRID-SUR",
			value: "J.A.M. MADRID-SUR",
			parent: "RC - Subdirección CENTRO",
		},
		{
			label: "Cabecera Gerencia Centro",
			value: "Cabecera Gerencia Centro",
			parent: "RC - Subdirección CENTRO",
		},
		{
			label: "Jef.Coord.Inver.Centro",
			value: "Jef.Coord.Inver.Centro",
			parent: "RC - Subdirección CENTRO",
		},
		{
			label: "J.A.M. ORENSE",
			value: "J.A.M. ORENSE",
			parent: "RC - Subdirección NOROESTE",
		},
		{ label: "J.A.M. LEÓN", value: "J.A.M. LEÓN", parent: "RC - Subdirección NOROESTE" },
		{
			label: "J.M. SALAMANCA",
			value: "J.M. SALAMANCA",
			parent: "RC - Subdirección NOROESTE",
		},
		{
			label: "Cabecera Gerencia Noroeste",
			value: "Cabecera Gerencia Noroeste",
			parent: "RC - Subdirección NOROESTE",
		},
		{
			label: "Jef.Coord.Inver.Noroeste",
			value: "Jef.Coord.Inver.Noroeste",
			parent: "RC - Subdirección NOROESTE",
		},
		{
			label: "Otro",
			value: "Otro",
			parent: "Otro",
		},
	],
	provincia: [
		"A Coruña",
		"Álava",
		"Albacete",
		"Alicante",
		"Almería",
		"Asturias",
		"Ávila",
		"Badajoz",
		"Baleares",
		"Barcelona",
		"Burgos",
		"Cáceres",
		"Cádiz",
		"Cantabria",
		"Castellón",
		"Ciudad Real",
		"Córdoba",
		"Cuenca",
		"Girona",
		"Granada",
		"Guadalajara",
		"Gipuzkoa",
		"Huelva",
		"Huesca",
		"Jaén",
		"La Rioja",
		"Las Palmas",
		"León",
		"Lérida",
		"Lugo",
		"Madrid",
		"Málaga",
		"Murcia",
		"Navarra",
		"Ourense",
		"Palencia",
		"Pontevedra",
		"Salamanca",
		"Segovia",
		"Sevilla",
		"Soria",
		"Tarragona",
		"Santa Cruz de Tenerife",
		"Teruel",
		"Toledo",
		"Valencia",
		"Valladolid",
		"Vizcaya",
		"Zamora",
		"Zaragoza",
		"Ceuta",
		"Melilla",
	],
	estado_ite: ["Programada", "En proceso", "Entregado", "Reenviado", "Registrado", "Rechazado"],
	resultado_ite: ["Pendiente", "Sin acciones", "Con acciones", "Proyecto"],
	empresa: ["CEMOSA", "UTE Viarium - Gilbartolome", "Arup", "ADIF", "Otra"],
	tipologia: defaultValuesTipologias,
	usos: defaultValuesUsos,
	especialidades: [
		"Infraestructura y Vía",
		"Telecomunicaciones",
		"Fibra óptica",
		"Instalaciones de seguridad eléctrica",
		"Instalaciones de seguridad mecánica",
		"Subestaciones",
		"Linea aérea de contacto",
	],
	relevancia: ["alta", "media", "baja"],
	estado_ficha_inventario: ["Pendiente", "Por aceptar", "Completado", "Rechazado"],
	estado_levantamiento: ["En proceso", "Entregado", "Registrado", "Rechazado"],
	por_validar: ["true", "false"],
	nee_categoria_mejoras: [
		"Características constructivas",
		"Características de la envolvente",
		"Suministros energéticos",
		"Iluminación",
		"Climatización",
		"Calefacción",
		"Refrigeración",
		"Ventilación",
		"Equipamiento",
		"Instalaciones frigoríficas",
	],
	nee_tipo_edificio: ["Uso Administrativo", "Uso mixto", "Uso industrial"],
	nec_indice: ["NCE4", "NCE3", "NCE2", "NCE1"],
	nee_indice: ["NEE4", "NEE3", "NEE2", "NEE1"],
	nae_indice: ["NAE3", "NAE2", "NAE1"],
	ncn_indice: ["NCNE3", "NCNE2", "NCNE1"],
	prioridad_reparacion: [
		"Riesgo grave o inminente",
		"Urgente (tan pronto como sea posible)",
		"Corto plazo (actuacion recomendable no mas de 12 meses)",
		"A medio plazo",
	],
};

export const defaultValues: Record<string, Choice[]> = Object.fromEntries(
	Object.entries(rawDefaults).map(([key, choices]) => [
		key,
		choices.map((choice) => (typeof choice === "string" ? { label: choice, value: choice } : choice)),
	]),
);

export const getUniqueChoices = (choices: Choice[]) => {
	const temp: Record<string, Choice> = {};
	for (const choice of choices) temp[choice.value] = choice;
	return Object.values(temp);
};
