import React, { useState } from "react";

import {
	Button,
	Divider,
	Flex,
	Grid,
	GridItem,
	Icon,
	IconButton,
	Input,
	Modal as ChakraModal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Spinner,
	Text,
	Textarea,
	useToast,
} from "@chakra-ui/react";
import { FiMail } from "react-icons/fi";
import { useParams } from "react-router-dom";

import { sendEmail } from "api/email";
import { EmailChipInput } from "common/components/EmailChipInput/EmailChipInput";

interface ModalProps {
	isOpen: boolean;
	onClose: () => void;
}

export const EmailModal: React.FC<ModalProps> = (props) => {
	const { isOpen, onClose } = props;
	const { iteId } = useParams();
	const [sending, setSending] = useState(false);
	const toast = useToast();

	return (
		<ChakraModal isOpen={isOpen} onClose={onClose} size="lg">
			<React.Fragment>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader color="gray.800" textStyle="body2" pt="2rem">
						<Grid templateColumns="repeat(8, 3fr)" w="100%" alignItems={"center"}>
							<GridItem gridColumn={"1/2"} gridRow={"1/2"} pl=".5rem">
								<IconButton title="Volver" aria-label="back" aria-hidden="true" variant="icon-only" height="2rem" width="2rem">
									<Icon as={FiMail} fontSize="2rem" strokeWidth={"1"} />
								</IconButton>
							</GridItem>
							<GridItem gridColumn={"2/8"} gridRow={"1/2"}>
								<Text textStyle="body1" color="gray.800" fontWeight="bold">
									Compartir ITE
								</Text>
							</GridItem>
						</Grid>
						<Divider w="98%" alignSelf={"center"} mt="1.25rem" />
					</ModalHeader>
					<ModalCloseButton onClick={() => onClose()} color="gray.500" />
					<ModalBody mb="2rem">
						<form
							onSubmit={(event) => {
								event.preventDefault();
								const form = event.currentTarget;
								const to = form.elements["to"].value.split(",");
								const subject = form.elements["subject"].value;
								const message = form.elements["message"].value;
								if (!iteId) return;
								setSending(true);
								sendEmail({ iteId, to, subject, message })
									.catch((err) => {
										toast({
											title: "Error al enviar.",
											description: "Por favor, vuelva a intentarlo",
											status: "error",
											duration: 5000,
											isClosable: true,
										});
										throw err
									})
									.then(() => {
										toast({
											title: "Correos enviados",
											description: "Los correos electrónicos han sido enviados",
											status: "success",
											duration: 2000,
											isClosable: true,
										});
									}).finally(() => {
										onClose();
										setSending(false);
									});
							}}
						>
							<Flex flexDirection="column" gap=".5rem">
								<Text>
									Se enviará un email a los destinatarios indicados, incluyendo una serie de PDFs adjuntos con los datos
									y resultados de la ITE.
								</Text>
								<Text>Enviar a:</Text>
								<EmailChipInput name="to" required={true} placeholder="nombre.apellidos@empresa.com" />
								<Text>Asunto:</Text>
								<Input name="subject" required={true} placeholder="Añadir asunto" />
								<Text>Comentarios:</Text>
								<Textarea name="message" required={true} placeholder="Añadir comentario" minH="10rem" />
							</Flex>
							<Divider my="1rem" />
							<Flex w="100%" gap=".5rem">
								<Button size="lg" minH="2.5rem" colorScheme="gray" flex="1" type="reset" onClick={onClose}>
									Cancelar
								</Button>
								<Button size="lg" minH="2.5rem" colorScheme="green" flex="1" type="submit">
									{sending ? <Spinner /> : <span>Enviar</span>}
								</Button>
							</Flex>
						</form>
					</ModalBody>
				</ModalContent>
			</React.Fragment>
		</ChakraModal>
	);
};
