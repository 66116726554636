import { useEffect, useState } from "react";

import { Box, Checkbox, Flex, Spinner } from "@chakra-ui/react";

import { BarAndValue } from "common/components/BarAndValue";
import { actions } from "store/portfolio/reducer";
import { useAppDispatch, useAppSelector } from "store/store";

export function Stats() {
	const portfolioStats = useAppSelector((state) => state.portfolio.portfolioStats);
	const filterSelection = useAppSelector((state) => state.portfolio.filterSelection);

	const dispatch = useAppDispatch();

	const [checks, setChecks] = useState<string[]>(
		filterSelection?.estadoITE || ["Programada", "En proceso", "Entregado", "Registrado", "Rechazado", "Reenviado"],
	);

	const totalValue = Object.values(portfolioStats.data?.estado_ite || {}).reduce((acc, current) => acc + current, 0);

	useEffect(() => {
		if (checks.length !== 5) dispatch(actions.setFilterSelection({ ...filterSelection, estadoITE: checks }));
		else dispatch(actions.setFilterSelection({ ...filterSelection, estadoITE: undefined }));
	}, [checks]);

	return (
		<Flex flexDirection="column" gap="1rem">
			{portfolioStats.thunkState !== "FULFILLED" && (
				<Flex height="5rem" justifyContent="center" alignItems="center">
					<Spinner />
				</Flex>
			)}
			{Object.keys(portfolioStats.data?.estado_ite || {}).map((currentKey) => {
				return (
					<Box key={currentKey}>
						<Checkbox
							colorScheme="gray"
							defaultChecked={checks.includes(currentKey)}
							onChange={(ev) => {
								const value = ev.target.checked;
								if (value) {
									setChecks((state) => {
										const asSet = new Set(state);
										asSet.add(currentKey as "Programada" | "En proceso" | "Entregado" | "Registrado" | "Rechazado" | "Reenviado");
										return Array.from(asSet);
									});
								} else {
									setChecks((state) => {
										const asSet = new Set(state);
										asSet.delete(currentKey as "Programada" | "En proceso" | "Entregado" | "Registrado" | "Rechazado" | "Reenviado");
										return Array.from(asSet);
									});
								}
							}}
						>
							{currentKey}
						</Checkbox>
						{checks.length > 0 && portfolioStats.data?.estado_ite[currentKey] ? (
							<BarAndValue
								value={portfolioStats.data?.estado_ite[currentKey] || 0}
								totalValue={totalValue}
								label={currentKey}
								colors={{
									Rechazado: "red.500",
									Registrado: "green.400",
									Entregado: "yellow.500",
									Reenviado: "orange.500",
									Programada: "gray.200",
									"En proceso": "gray.200",
								}}
							/>
						) : (
							<></>
						)}
					</Box>
				);
			})}
		</Flex>
	);
}
