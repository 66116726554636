import React from "react";

import {
	Button,
	Divider,
	Flex,
	Grid,
	GridItem,
	Icon,
	IconButton,
	Modal as ChakraModal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Text,
	useToast,
	Textarea,
} from "@chakra-ui/react";
import { FiAlertTriangle } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

import { ActivoCompletoOutput } from "components/Portfolio/types/activos";
import { putRechazarActivoThunk } from "store/portfolio/thunks";
import { useAppDispatch } from "store/store";

import HeaderText from "../Header";

interface IProps {
	asset?: ActivoCompletoOutput;
	isOpen: boolean;
	onClose: () => void;
}
type PropsType = IProps;

const RejectAssetModal: React.FC<PropsType> = (props) => {
	const { asset, isOpen, onClose } = props;
	const dispatch = useAppDispatch();
	const toast = useToast();
	const navigate = useNavigate();

	const [comment, setComment] = React.useState<string | null>(null);
	const notReady = !comment || comment == "";

	const saveComment = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		const value = e.target.value;
		setComment(value);
	};

	const handleSubmit = async () => {
		try {
			if (asset?.id && comment) {
				await dispatch(putRechazarActivoThunk({ assetId: asset.id, comentario: comment }));
				toast({
					title: "Respuesta enviada",
					status: "success",
					duration: 3000,
					isClosable: true,
				});
				navigate("/portfolio");
			} else {
				toast({
					title: "Error al enviar respuesta",
					status: "error",
					duration: 3000,
					isClosable: true,
				});
			}
			onClose();
		} catch (error) {
			toast({
				title: "Error al enviar respuesta",
				status: "error",
				duration: 3000,
				isClosable: true,
			});
		}
	};

	return (
		<ChakraModal isOpen={isOpen} onClose={onClose} size="2xl">
			{asset && (
				<React.Fragment>
					<ModalOverlay />
					<ModalContent>
						<ModalHeader color="gray.800" textStyle="body2" pt="2rem">
							<Grid templateColumns="repeat(8, 3fr)" w="100%" alignItems={"center"}>
								<GridItem gridColumn={"1/2"} gridRow={"1/2"} pl=".5rem">
									<IconButton title="Volver" aria-label="back" aria-hidden="true" variant="icon-only" height="2rem" width="2rem">
										<Icon as={FiAlertTriangle} fontSize="3rem" color="red.500" strokeWidth={"1"} />
									</IconButton>
								</GridItem>
								<GridItem gridColumn={"2/8"} gridRow={"1/2"}>
									<Text textStyle="body1" color="gray.800" fontWeight="bold">
										Eliminar activo
									</Text>
								</GridItem>
							</Grid>
							<Divider w="98%" alignSelf={"center"} mt="1.25rem" />
						</ModalHeader>
						<ModalCloseButton onClick={() => onClose()} color="gray.500" />
						<ModalBody mb="2rem">
							<Grid templateColumns="repeat(8, 3fr)" w="100%" alignItems={"baseline"}>
								<GridItem gridColumn={"1/4"} gridRow={"1/2"}>
									<React.Fragment>
										<Text textStyle="body3" color="gray.600" lineHeight="1.25rem" mb="1rem">
											{/* eslint-disable-next-line max-len */}
											Elimina el activo
										</Text>
									</React.Fragment>
								</GridItem>
								<GridItem gridColumn={"5/9"} gridRow={"1/3"}>
									<Textarea name="comment" onChange={saveComment} h="5rem" />
									<Flex mt=".5rem">
										<Text textStyle="body3" color="red.500">
											*
										</Text>
										<HeaderText label="Comentario Requerido" textProps={{ ml: ".25rem" }} />
									</Flex>
								</GridItem>
								<GridItem gridColumn={"1/9"} gridRow={"3/4"} pt="1.5rem">
									<Divider w="98%" alignSelf={"center"} mb="1.5rem" />
									<Flex direction="row" justifyContent="space-between">
										<Button
											type="submit"
											bg="gray.400"
											size="md"
											borderRadius="5px"
											h="2rem"
											w="20%"
											onClick={onClose}
											_hover={{ bg: "gray.500" }}
										>
											<Text textStyle="body3" color="white">
												Cancelar
											</Text>
										</Button>
										<Button
											isDisabled={notReady ? true : false}
											type="submit"
											bg={"red.500"}
											size="md"
											ml="1rem"
											borderRadius="5px"
											mr=".5rem"
											h="2rem"
											w="20%"
											onClick={() => handleSubmit()}
										>
											<Text textStyle="body3" color="white">
												Eliminar
											</Text>
										</Button>
									</Flex>
								</GridItem>
							</Grid>
						</ModalBody>
					</ModalContent>
				</React.Fragment>
			)}
		</ChakraModal>
	);
};

export default RejectAssetModal;
