import React from "react";

import { Box, Button, Flex, Text, useDisclosure } from "@chakra-ui/react";

import { InfoLine } from "common/components/InfoLine";
import { ITEOutput } from "components/ITE/types/ite";
import { useAppSelector } from "store/store";

import { IteValidationModal } from "../IteValidationModal";

interface IProps {
	ite?: ITEOutput | null;
	print?: boolean;
}

export const IteValidation: React.FC<IProps> = (props) => {
	const { ite, print } = props;

	const features = useAppSelector((state) => state.user.features);

	const [iteForValidation, setIteForValidation] = React.useState<ITEOutput | null>(null);
	const [iteValidation, setIteValidation] = React.useState<boolean | null>(null);

	const { isOpen, onOpen, onClose } = useDisclosure();

	const openIteModal = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, ite: ITEOutput, validation: boolean) => {
		e.stopPropagation();
		setIteForValidation(ite);
		onOpen();
		setIteValidation(validation);
	};

	if (!ite) return <React.Fragment />;

	return (
		<React.Fragment>
			{ite.ite.comentario.length > 0 ? (
				<Box>
					<InfoLine label="Comentario:" value={ite.ite.comentario} />
				</Box>
			) : (
				<Box>
					{!print && (
						<Flex justifyContent={{ xs: "space-around", sm: "flex-start" }} alignItems="center">
							{features.data.includes("validate-ite") ? (
								<Button
									type="submit"
									bg={"green.500"}
									_hover={
										!["Entregado", "Reenviado"].includes(ite.ite.estado) || ite.asset.por_validar === undefined || ite.asset.por_validar
											? {}
											: { bg: "green.600" }
									}
									size="md"
									mr="1rem"
									borderRadius="5px"
									h="2rem"
									flex="auto"
									onClick={(e) => openIteModal(e, ite, true)}
									isDisabled={
										!["Entregado", "Reenviado"].includes(ite.ite.estado) || ite.asset.por_validar === undefined || ite.asset.por_validar
									}
								>
									<Text textStyle="body2" color="white">
										Registrar
									</Text>
								</Button>
							) : (
								<></>
							)}
							{features.data.includes("reject-ite") ? (
								<Button
									type="submit"
									bg={"red.500"}
									_hover={
										!["Entregado", "Reenviado"].includes(ite.ite.estado) || ite.asset.por_validar === undefined || ite.asset.por_validar
											? {}
											: { bg: "red.600" }
									}
									size="md"
									borderRadius="5px"
									h="2rem"
									flex="auto"
									onClick={(e) => openIteModal(e, ite, false)}
									isDisabled={!["Entregado", "Reenviado"].includes(ite.ite.estado)}
								>
									<Text textStyle="body2" color="white">
										Rechazar
									</Text>
								</Button>
							) : (
								<></>
							)}
						</Flex>
					)}
				</Box>
			)}
			<IteValidationModal isOpen={isOpen} onClose={onClose} ite={iteForValidation} iteAnswer={iteValidation} />
		</React.Fragment>
	);
};
