
import React from "react";

import { useNavigate } from "react-router-dom";

import { ModalComentarios } from "common/components/Comentarios";
import { useAppSelector } from "store/store";

export const ComentariosActivo: React.FC = () => {
	const navigate = useNavigate();
	const iteSelected = useAppSelector((state) => state.ites.iteSelected);

	return (
		<ModalComentarios asset={iteSelected.data?.asset} onClose={() => navigate(-1)} />
	);
};
