import React, { useEffect, useState } from "react";

import { Box } from "@chakra-ui/react";
import "mapbox-gl/dist/mapbox-gl.css";
import MapComponent, { MapRef, Marker } from "react-map-gl";

import { MarkerData } from "common/components/Map/MarkerData";
import { useAppSelector } from "store/store";

interface IProps {
	points: MarkerData[];
	children: React.ReactNode;
	mapOnClick: () => void;
}

const calcZoom = (width: number) => {
	if (width < 415) return 4.25; // Mobile
	if (width < 830) return 5.25; // Tablet
	if (width < 2500) return 5.5; // Desktop
	if (width < 3900) return 6.5; // Large Screens
	return 1;
};

const calcInitialLatitude = (width: number) => {
	if (width < 415) return 42.5;
	if (width < 830) return 41;
	if (width < 2500) return 40.416775;
	if (width < 3900) return 40.416775;
	return 40.416775;
};

const calcInitialLongitude = (width: number) => {
	if (width < 415) return -3;
	if (width < 830) return -3.5;
	if (width < 2500) return -5.25;
	if (width < 3900) return -5.25;
	return -5.25;
};

const getStyleUrl = (style: string) => {
	switch (style) {
		case "satellite":
			return "mapbox://styles/mapbox/satellite-v9";
		default:
			return "mapbox://styles/dhub/cl2ypoavr000z14n5af53rj77";
	}
};

export const MapboxPortfolioComponent: React.FC<IProps> = (props) => {
	const [mapRef, setMapRef] = useState<MapRef | undefined>();
	const mapStyle = useAppSelector((state) => state.user.mapStyle);

	const mapRefCallback = React.useCallback((ref: MapRef) => {
		if (ref) {
			setMapRef(ref);
		}
	}, []);

	useEffect(() => {
		const resizeMapbox = () => {
			setTimeout(() => mapRef?.resize(), 1000);
		};
		window.addEventListener("resize", resizeMapbox);
		return () => window.removeEventListener("resize", resizeMapbox);
	});

	const getColor = (type: string) => {
		switch (type) {
			case "Rechazado":
				return "var(--chakra-colors-red-400)";
			case "Entregado":
				return "var(--chakra-colors-yellow-400)";
			case "Registrado":
				return "var(--chakra-colors-green-400)";
			case "Reenviado":
				return "var(--chakra-colors-orange-500)";
			default:
				return "var(--chakra-colors-gray-500)";
		}
	};

	return (
		<Box
			width="100%"
			height="100%"
			id="map-container"
			position="absolute"
			top="0"
			left="0"
			zIndex="0"
			pointerEvents="auto"
		>
			<MapComponent
				mapStyle={getStyleUrl(mapStyle)}
				mapboxAccessToken="pk.eyJ1IjoiZGh1YiIsImEiOiJjbGUyczIyODQwMXdrM3dseG53MnMxaXI4In0.FYGuulRoBvKZ56vsbIXpzA"
				initialViewState={{
					zoom: calcZoom(window.innerWidth),
					latitude: calcInitialLatitude(window.innerWidth),
					longitude: calcInitialLongitude(window.innerWidth),
				}}
				onClick={props.mapOnClick}
				ref={mapRefCallback}
			>
				{props.points.map((data) => {
					return (
						<Marker
							key={data.id}
							longitude={data.longitude}
							latitude={data.latitude}
							onClick={(e) => {
								e.originalEvent.stopPropagation();
								data.onClick && data.onClick();
							}}
							color={getColor(data.type)}
							scale={0.6}
							style={{
								cursor: "pointer",
							}}
						/>
					);
				})}
				{props.children}
			</MapComponent>
		</Box>
	);
};
