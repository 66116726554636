import React from "react";

import { Box, Flex, Grid, GridItem, Icon } from "@chakra-ui/react";
import { BsCheckCircle, BsExclamationTriangle } from "react-icons/bs";

import { BotonComentarios } from "common/components/Comentarios";
import { PortfolioDateInput } from "common/components/PortfolioDateInput";
import { PortfolioInfoLine } from "common/components/PortfolioInfoLine";
import { ActivoCompletoOutput } from "components/Portfolio/types/activos";
import { putFechaEntregaCertificacionThunk } from "store/portfolio/thunks";
import { useAppDispatch, useAppSelector } from "store/store";


interface IProps {
	assetId: string;
	onOpenComentario: (asset: ActivoCompletoOutput) => void;
}

const CertificacionRow: React.FC<IProps> = (props) => {
	const dispatch = useAppDispatch();

	const features = useAppSelector((state) => state.user.features.data);
	const asset = useAppSelector((state) => state.portfolio.assetsInfo.data.data.find((it) => it.id === props.assetId));
	const hasCertifyFeature = features.indexOf("certify") >= 0;

	if (!asset) return <React.Fragment />;
	const onDateChange = async (date: Date | undefined, tipo: "bim" | "ite") => {
		await dispatch(putFechaEntregaCertificacionThunk([{ iteId: asset.ite_id, tipo, fecha: date?.toISOString() }]));
	};

	return (
		<Box p=".5rem 0" rounded="md" bgColor="white" mt=".5rem" pl="1rem" h="2.5rem">
			<Flex height="100%" alignItems="center">
				<Grid templateColumns="repeat(32, 1fr)" w="100%" color="gray.800" alignItems="center">
					<GridItem colSpan={2} pt=".5rem" pl="1.5rem">
						{asset.por_validar === undefined || asset.por_validar ? (
							<Icon as={BsExclamationTriangle} color="red.500" fontSize="1.25rem" />
						) : (
							<Icon as={BsCheckCircle} color="green.500" fontSize="1.25rem" />
						)}
					</GridItem>
					<GridItem data-testid="assetName" colSpan={6}>
						<PortfolioInfoLine href={`/activo/${asset.id}/portada/${asset.ite_id}`} value={asset.nombre} />
					</GridItem>
					{asset && (
						<React.Fragment>
							<GridItem colSpan={4}>
								<PortfolioInfoLine value={asset.empresa} />
							</GridItem>
							<GridItem colSpan={4}>
								<PortfolioDateInput column="fecha_entrega_bim" value={asset.fecha_entrega_bim} editable={false} />
							</GridItem>
							<GridItem colSpan={4}>
								<PortfolioDateInput
									column="fecha_certificacion_bim"
									value={asset.fecha_certificacion_bim}
									editable={hasCertifyFeature}
									onChange={(v) => onDateChange(v, "bim")}
								/>
							</GridItem>
							<GridItem colSpan={4}>
								<PortfolioDateInput column="fecha_entrega" value={asset.fecha_entrega} editable={false} />
							</GridItem>
							<GridItem colSpan={4}>
								<PortfolioDateInput
									column="fecha_certificacion_ite"
									value={asset.fecha_certificacion_ite}
									editable={hasCertifyFeature}
									onChange={(v) => onDateChange(v, "ite")}
								/>
							</GridItem>
							<GridItem colSpan={4}>
								<BotonComentarios
									numero={asset.numero_comentarios || 0}
									onOpenComentario={() => props.onOpenComentario(asset)}
								/>
							</GridItem>
						</React.Fragment>
					)}
				</Grid>
			</Flex>
		</Box>
	);
};

export default React.memo(CertificacionRow, (prev, next) => prev.assetId === next.assetId);
