import React from "react";

import {
	Button,
	Divider,
	Flex,
	Grid,
	GridItem,
	Icon,
	IconButton,
	Input,
	Modal as ChakraModal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Text,
	Textarea,
	useToast,
} from "@chakra-ui/react";
import { FiAlertTriangle } from "react-icons/fi";

import HeaderText from "common/components/Header";
import { ITEOutput } from "components/ITE/types/ite";
import {
	putIteValidarThunk,
	putIteRechazarThunk,
	getIteListThunk,
	getIteByIdThunk,
	getReportsThunk,
} from "store/ITE/thunks";
import { useAppDispatch } from "store/store";

interface ModalProps {
	isOpen: boolean;
	onClose: () => void;
	ite: ITEOutput | null;
	iteAnswer: boolean | null;
	isAsset?: boolean;
}

export const IteValidationModal: React.FC<ModalProps> = (props) => {
	const dispatch = useAppDispatch();
	const { isOpen, onClose, ite, iteAnswer: isAproval, isAsset } = props;

	const [comment, setComment] = React.useState<string | null>(null);
	const toast = useToast();
	const notReady = !comment || comment == "";

	const saveComment = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		const value = e.target.value;
		setComment(value);
	};

	const addDays = (date: Date, days: number) => {
		date.setDate(date.getDate() + days);
		return date;
	};

	const addYears = (date: Date, years: number) => {
		date.setFullYear(date.getFullYear() + years);
		return date;
	};

	const lastInspectionDate = new Date(
		ite?.ite.fecha_visita_reportada ||
		ite?.ite.fecha_entrega ||
		ite?.ite.fecha_visita_reprogramada ||
		ite?.ite.fecha_visita_programada ||
		new Date(),
	);
	const defaultNextDate = addYears(lastInspectionDate, 10);

	const [date, setDate] = React.useState(defaultNextDate);

	const dateToISODateString = (date: Date) => date.toISOString().split("T")[0];

	const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
		e.preventDefault();
		const { value } = e.target;
		setDate(new Date(value));
	};

	const handleSubmit = async () => {
		try {
			if (ite && isAproval && comment) {
				if (ite.ite.tipo === "Principal") {
					await dispatch(
						putIteValidarThunk({ id: ite.ite.id, fechaNuevaITE: date.toISOString(), comentario: comment }),
					);
				} else {
					await dispatch(putIteValidarThunk({ id: ite.ite.id, comentario: comment }));
				}
				await dispatch(getIteListThunk({ assetId: ite.asset.id }));
				dispatch(getIteByIdThunk({ id: ite.ite.id }));
				dispatch(getReportsThunk({ iteId: ite.ite.id }));
			} else if (ite && comment) {
				await dispatch(putIteRechazarThunk({ id: ite.ite.id, comentario: comment }));
				await dispatch(getIteByIdThunk({ id: ite.ite.id }));
			}
			toast({
				title: "Respuesta enviada",
				status: "success",
				duration: 3000,
				isClosable: true,
			});
			onClose();
		} catch (error) {
			toast({
				title: "Error al enviar respuesta",
				status: "error",
				duration: 3000,
				isClosable: true,
			});
		}
	};

	return (
		<ChakraModal isOpen={isOpen} onClose={onClose} size="2xl">
			{ite && (
				<React.Fragment>
					<ModalOverlay />
					<ModalContent>
						<ModalHeader color="gray.800" textStyle="body2" pt="2rem">
							<Grid templateColumns="repeat(8, 3fr)" w="100%" alignItems={"center"}>
								<GridItem gridColumn={"1/2"} gridRow={"1/2"} pl=".5rem">
									<IconButton title="Volver" aria-label="back" aria-hidden="true" variant="icon-only" height="2rem" width="2rem">
										<Icon as={FiAlertTriangle} fontSize="3rem" color="red.500" strokeWidth={"1"} />
									</IconButton>
								</GridItem>
								<GridItem gridColumn={"2/8"} gridRow={"1/2"}>
									{isAproval ? (
										<Text textStyle="body1" color="gray.800" fontWeight="bold">
											Registrar la ITE es una accion definitiva
										</Text>
									) : (
										<React.Fragment>
											{isAsset ? (
												<Text textStyle="body1" color="gray.800" fontWeight="bold">
													Rechazar Activo
												</Text>
											) : (
												<Text textStyle="body1" color="gray.800" fontWeight="bold">
													Rechazar ITE
												</Text>
											)}
										</React.Fragment>
									)}
								</GridItem>
							</Grid>
							<Divider w="98%" alignSelf={"center"} mt="1.25rem" />
						</ModalHeader>
						<ModalCloseButton onClick={() => onClose()} color="gray.500" />
						<ModalBody mb="2rem">
							<Grid templateColumns="repeat(8, 3fr)" w="100%" alignItems={"baseline"}>
								<GridItem gridColumn={"1/4"} gridRow={"1/2"}>
									{isAproval ? (
										<>
											{ite.ite.tipo === "Principal" ? (
												<>
													<Text textStyle="body3" color="gray.600" lineHeight="1.25rem">
														Para validar y cerrar definitivamente la ITE, una vez hayas finalizado la revisión, incluye
														un comentario de registro en el campo requerido de esta ventana y programa la próxima ITE.
													</Text>
													<Text textStyle="body3" color="gray.600" lineHeight="1.25rem">
														Ten en cuenta que los trabajos de la futura ITE podrán dar comienzo hasta un año antes de la
														fecha de visita programada.
													</Text>
												</>
											) : (
												<Text textStyle="body3" color="gray.600" lineHeight="1.25rem">
													Para validar y cerrar definitivamente la ITE, revisa y completa el resumen final.
												</Text>
											)}
										</>
									) : (
										<React.Fragment>
											{isAsset ? (
												<Text textStyle="body3" color="gray.600" lineHeight="1.25rem" mb="1rem">
													{/* eslint-disable-next-line max-len */}
													Actualiza el resumen del estado actual, especificando el motivo por el cual se rechaza los
													datos introducidos en el formulario de inventario.
												</Text>
											) : (
												<Text textStyle="body3" color="gray.600" lineHeight="1.25rem" mb="1rem">
													{/* eslint-disable-next-line max-len */}
													Actualiza el resumen del estado actual, especificando el motivo por el cual se rechaza la ITE.
												</Text>
											)}
										</React.Fragment>
									)}
								</GridItem>
								<GridItem gridColumn={"5/9"} gridRow={"1/3"}>
									<Textarea name="comment" onChange={saveComment} h="5rem" />
									<Flex mt=".5rem">
										<Text textStyle="body3" color="red.500">
											*
										</Text>
										<HeaderText label="Comentario Requerido" textProps={{ ml: ".25rem" }} />
									</Flex>
								</GridItem>
								<GridItem gridColumn={"1/4"} gridRow={"2/3"} pt="2rem">
									{isAproval && ite.ite.tipo === "Principal" && (
										<React.Fragment>
											<Text textStyle="body3" color="gray.800" fontWeight="bold" lineHeight="1.25rem" mb="1rem">
												Programación Próxima ITE Principal
											</Text>
											<Input
												name="fechaProximaITE"
												h="38px"
												fontSize="body3"
												type="date"
												defaultValue={dateToISODateString(defaultNextDate)}
												min={dateToISODateString(addDays(new Date(), 1))}
												onChange={handleChange}
												lang="es-ES"
											/>
										</React.Fragment>
									)}
								</GridItem>
								<GridItem gridColumn={"1/9"} gridRow={"3/4"} pt="1.5rem">
									<Divider w="98%" alignSelf={"center"} mb="1.5rem" />
									<Flex direction="row" justifyContent="space-between">
										<Button
											type="submit"
											bg="gray.400"
											size="md"
											borderRadius="5px"
											h="2rem"
											w="20%"
											onClick={onClose}
											_hover={{ bg: "gray.500" }}
										>
											<Text textStyle="body3" color="white">
												Cancelar
											</Text>
										</Button>
										<Button
											isDisabled={notReady ? true : false}
											type="submit"
											bg={isAproval ? "green.500" : "red.500"}
											size="md"
											ml="1rem"
											borderRadius="5px"
											mr=".5rem"
											h="2rem"
											w="20%"
											onClick={() => handleSubmit()}
										>
											<Text textStyle="body3" color="white">
												{isAproval ? "Registrar" : "Rechazar"}
											</Text>
										</Button>
									</Flex>
								</GridItem>
							</Grid>
						</ModalBody>
					</ModalContent>
				</React.Fragment>
			)}
		</ChakraModal>
	);
};
