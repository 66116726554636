import React from "react";

import { Box, Grid, GridItem, SlideFade, SlideFadeProps, Text, useDisclosure } from "@chakra-ui/react";

import { urlFromFilepath } from "common/components/Carousel";
import Modal from "common/components/Carousel/Modal";
import { useAppSelector } from "store/store";

import { Mapbox } from "./components/Mapbox";

export const Portada: React.FC = () => {
	const { isOpen, onToggle } = useDisclosure();
	const iteSelected = useAppSelector((state) => state.ites.iteSelected.data);
	const [signedUrl, setSignedUrl] = React.useState<string>();
	const [display, setDisplay] = React.useState<"none" | "block">("none");

	const getImageSignedUrl = (imageKey?: string) => {
		if (!imageKey) return "/placeholder.png";
		try {
			let normalizedInput = imageKey.trim();
			if (normalizedInput.startsWith("{") && normalizedInput.endsWith("}")) {
				// Remove the outer braces
				normalizedInput = normalizedInput.slice(1, -1).replace(/\\/g, "").replace(/"{/g, "{").replace(/}"/g, "}");
				// Wrap the content in square brackets to make it an array
				normalizedInput = `[${normalizedInput}]`;
			}
			const fp = JSON.parse(normalizedInput).filter((it) => it._is_deleted !== true)[0].filepath;
			if (!fp) return "/placeholder.png";
			return urlFromFilepath(fp);
		} catch (err) {
			return "/placeholder.png";
		}
	};

	React.useEffect(() => {
		const signedUrl = getImageSignedUrl(iteSelected?.asset.foto_portada);
		setSignedUrl(signedUrl);
	}, [iteSelected?.asset.foto_portada]);

	const slideProps: SlideFadeProps = {
		in: isOpen,
		offsetX: "0px",
		offsetY: "0px",
	};

	const [imgError, setImgError] = React.useState(false);
	const [retryCount, setRetryCount] = React.useState(0);
	const maxRetries = 3;

	React.useEffect(() => {
		if (imgError && retryCount < maxRetries) {
			const timer = setTimeout(() => {
				setRetryCount(retryCount + 1);
				setImgError(false); // Reintentar la carga
			}, 1000); // Espera 1 segundo antes de reintentar

			return () => clearTimeout(timer); // Limpia el timeout al desmontar
		}
	}, [imgError, retryCount]);

	const handleError = () => {
		if (retryCount < maxRetries) {
			setImgError(true);
		} else {
			console.error("La imagen falló después de varios intentos");
		}
	};

	const geometria = iteSelected?.asset.geometria;

	React.useEffect(() => {
		onToggle();
	}, []);
	return (
		<SlideFade {...slideProps}>
			<Box p="1rem" rounded="md" boxShadow="sm" bgColor="white" pb="2rem">
				<Text textStyle="body2" fontWeight="bold" pl="1rem" mb="2rem">
					Portada
				</Text>
				<Grid templateColumns="repeat(2, 1fr)" gap={6}>
					{iteSelected?.asset.descripcion_general && (
						<GridItem colSpan={2} pl="1rem" display="flex" gap=".4rem" flexWrap="wrap">
							<Text textStyle="body3" color="gray.500" display="inline">
								Descripción general del activo
							</Text>
							<Text textStyle="body3" display="inline">
								{iteSelected?.asset.descripcion_general}
							</Text>
						</GridItem>)
					}
					<GridItem w="100%">
						<Box display="flex" justifyContent="center" alignItems="center" h="100%" w="100%">
							{signedUrl && imgError === false && (
								<React.Fragment>
									<div id="fotoportada">
										<img
											loading="lazy"
											src={signedUrl}
											onClick={() => {
												setDisplay("block");
											}}
											onError={handleError}
										/>
									</div>
									<Modal url={signedUrl} display={display} setDisplay={setDisplay} />
								</React.Fragment>
							)}
						</Box>
					</GridItem>
					<GridItem w="100%">
						<Box>
							<Mapbox value={geometria?.coordinates} />
						</Box>
					</GridItem>
				</Grid>
			</Box>
		</SlideFade>
	);
};
