import React, { useEffect } from "react";

import { Box, Flex, Grid, GridItem } from "@chakra-ui/react";

import { THUNK_STATE } from "config/types";
import { useAppDispatch, useAppSelector } from "store/store";
import { getTecnicosThunk } from "store/user/thunks";

import { defaultValues } from "../../../defaultValues";
import { FilterDropDown } from "../../components/FilterDropDown";
import { FilterDropDownDate } from "../../components/FilterDropDown/FilterDropdownDate";

export const InspeccionHeader: React.FC = () => {
	const dispatch = useAppDispatch();
	const tecnicos = useAppSelector((state) => state.user.tecnicos);

	useEffect(() => {
		if (tecnicos.thunkState === THUNK_STATE.FULFILLED) return;
		dispatch(getTecnicosThunk());
	}, []);

	return (
		<Box h=".5rem" background="transparent" p="0 1rem" mb=".5rem">
			<Flex width="100%" height="100%" alignItems="center">
				<Grid templateColumns="repeat(23, 1fr)" w="100%" pr="1.5rem" pl=".3rem">
					<GridItem colSpan={2}>
						<FilterDropDown column="por_validar" values={defaultValues.por_validar} />
					</GridItem>
					<GridItem colSpan={5}>
						<FilterDropDown column="nombre" />
					</GridItem>
					<GridItem colSpan={2}>
						<Flex>
							<FilterDropDown column="estado_ite" values={defaultValues.estado_ite} />
						</Flex>
					</GridItem>
					<GridItem colSpan={2}>
						<FilterDropDown column="resultado_ite" values={defaultValues.resultado_ite} />
					</GridItem>
					<GridItem colSpan={2}>
						<FilterDropDown column="empresa" values={defaultValues.empresa} />
					</GridItem>
					<GridItem colSpan={3}>
						<FilterDropDown
							column="tecnico"
							values={tecnicos.data.filter((it) => it && it.includes("@")).map((it) => ({ label: it, value: it }))}
						/>
					</GridItem>
					<GridItem colSpan={3}>
						<FilterDropDown column="autor" />
					</GridItem>
					<GridItem colSpan={2}>
						<FilterDropDownDate column="fecha_visita_programada" />
					</GridItem>
					<GridItem colSpan={2}>
						<FilterDropDownDate column="fecha_visita_programada_prox_ite" />
					</GridItem>
				</Grid>
			</Flex>
		</Box>
	);
};
